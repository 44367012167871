import React, { useCallback, useMemo } from 'react';
import { Pressable, Text as RNText, View } from 'react-native';

import { SVGLocalLoader, Title } from '@ere-uilib/atoms';
import { CheckBox, RadioButton } from '@ere-uilib/molecules';
import { InputEditAmountWithLinkAndContribution } from '@ere-uilib/organisms/InputEditAmountWithLinkAndContribution';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { FundSourceEnum } from '@modules/funds/types';
import { useTranslation } from '@translations/index';

import { SavingRepartitionCardPropsType } from './interfaces';
import { SavingRepartitionCardSelectTypeEnum } from './sharedInterfaces';
import { getStyles } from './styles';

export const SavingRepartitionCard: React.FC<
  React.PropsWithChildren<SavingRepartitionCardPropsType>
> = ({
  children,
  // common main props
  supportName,
  onCardPress,
  disabled,
  editable,
  legalStatus,

  // selection props
  isSelectable = false,
  isSelected,
  onSelectChange,
  selectType = null,

  // right amount part
  amount,
  onEditAmountPress,
  fieldAmount,
  isRefund,
  fieldPercent,
  isFieldAmountPercentage,
  linkUnderfieldLabel,
  isLinkUnderfieldAlwaysEnabled,
  isLinkUnderField,
  onLinkUnderFieldPress,
  cardTitle,
  onResetAmountPress,
  // contribution props
  isContributionDisplayed,
  isContributionLast,
  isContributionError,
  contribution = undefined,
  drivenSupport,
  // simple optionnals props
  investedAmount,
  availableAmount,
  transferableAmount,
  arbitrableAmount,
  shareCount,
  riskLevel,
  isRiskLevelSRI = true,
  performanceValue,
  addedValue,
  valorisationDate,
  nextValorisationDate,
  isSolidaire,
  isFieldPercentagePlusAmount,
  hasNoFundSheet,
  source,
  // styles
  style,
  subStyles,
  //test
  testId,
  checkboxTestId,
  shouldDisplayAddedValue,
  isStyleAlreadyHandled = false
}) => {
  //HERE SET UP IF THERE IS NO Saving DETAILS (CCB ? RC ?)
  const isFundsSheetDisabled =
    !onCardPress || (!!legalStatus && ['CCB', 'AD'].includes(legalStatus));

  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles(
    {
      theme,
      isMobile,
      isFundsSheetDisabled,
      disabled,
      editable
    },
    {
      style,
      ...subStyles
    }
  );

  const {
    formatCurrencyNumber,
    formatMessage,
    formatShareCountNumber,
    formatPercentNumberWithPlusMinus,
    formatCurrencyNumberWithPlusMinus,
    formatDate
  } = useTranslation();

  const disabledLink = useMemo(() => {
    if (!hasNoFundSheet) return false;

    return source === FundSourceEnum.UNKNOWN;
  }, [hasNoFundSheet, source]);

  const renderCommonData = useCallback(
    ({ label, value, valueColor }: { label: string; value: string; valueColor?: string }) => {
      return (
        <Title
          style={styles.commonLabelTextStyle}
          variant="t7"
          weight="light"
        >
          {label}
          <RNText
            style={[
              {
                // set before styles because styles should replace color when disabled.
                color: valueColor
              },
              styles.commonValueTextStyle
            ]}
          >
            {value}
          </RNText>
        </Title>
      );
    },
    [styles]
  );

  const renderAmountAlreadyInvested = useCallback(() => {
    if (typeof investedAmount !== 'number' || investedAmount <= 0) {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_actualposition_label' }),
      value: formatCurrencyNumber({ value: investedAmount })
    });
  }, [investedAmount, formatMessage, formatCurrencyNumber, renderCommonData]);

  const renderArbitrableAmount = useCallback(() => {
    if (typeof arbitrableAmount !== 'number' || arbitrableAmount <= 0) {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_transferable_amount_label' }),
      value: formatCurrencyNumber({ value: arbitrableAmount })
    });
  }, [arbitrableAmount, formatMessage, formatCurrencyNumber, renderCommonData]);

  const renderTransferableAmount = useCallback(() => {
    if (typeof transferableAmount !== 'number' || transferableAmount <= 0) {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_transferable_amount_label' }),
      value: formatCurrencyNumber({ value: transferableAmount })
    });
  }, [transferableAmount, formatMessage, formatCurrencyNumber, renderCommonData]);

  const renderAvailableAmount = useCallback(() => {
    if (typeof availableAmount !== 'number' || availableAmount <= 0) {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_available_amount_label' }),
      value: formatCurrencyNumber({ value: availableAmount })
    });
  }, [availableAmount, formatMessage, formatCurrencyNumber, renderCommonData]);

  const renderShareCount = useCallback(() => {
    if (typeof shareCount !== 'number' || shareCount <= 0) {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_share_count_label' }),
      value: formatShareCountNumber({ value: shareCount })
    });
  }, [shareCount, formatMessage, formatShareCountNumber, renderCommonData]);

  const renderAddedvalue = useCallback(() => {
    if (typeof addedValue !== 'number' || !shouldDisplayAddedValue) return null;

    return renderCommonData({
      label: formatMessage({ id: 'Saving_Tab1_Item_value_label' }),
      value: formatCurrencyNumberWithPlusMinus({ value: addedValue }),
      valueColor:
        addedValue < 0 ? theme.colors.amounts.negative.c500 : theme.colors.amounts.positive.c500
    });
  }, [addedValue, formatMessage, formatCurrencyNumberWithPlusMinus, theme, renderCommonData]);

  const renderRiskLevel = useCallback(() => {

    const isRiskLevelValid = riskLevel && !hasNoFundSheet && source !== FundSourceEnum.UNKNOWN;
    if(!isRiskLevelValid) {
      return null;
    }

    const labelId = isRiskLevelSRI
      ? 'Saving_Tab1_item_risklevel_label'
      : 'Saving_Tab1_item_risklevel_SRRI_Old_label';

    return renderCommonData({
      label: formatMessage({ id: labelId }),
      value: `${riskLevel}/7`
    });
  }, [riskLevel, isRiskLevelSRI, formatMessage, renderCommonData]);

  const renderPerformance = useCallback(() => {
    if (typeof performanceValue !== 'number' || performanceValue === 0) {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'Saving_Tab1_item_perfYTD_label' }),
      value: formatPercentNumberWithPlusMinus({ value: performanceValue }),
      valueColor:
        performanceValue < 0
          ? theme.colors.amounts.negative.c500
          : theme.colors.amounts.positive.c500
    });
  }, [performanceValue, formatMessage, formatPercentNumberWithPlusMinus, theme, renderCommonData]);

  const renderValorisationDate = useCallback(() => {
    if (typeof valorisationDate !== 'string') {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_datevl_label' }),
      value: formatDate({ value: valorisationDate })
    });
  }, [valorisationDate, formatMessage, formatDate, renderCommonData]);

  const renderNextValorisationDate = useCallback(() => {
    if (typeof nextValorisationDate !== 'string') {
      return null;
    }
    return renderCommonData({
      label: formatMessage({ id: 'repartition_support_nextvl_label' }),
      value: formatDate({ value: nextValorisationDate })
    });
  }, [nextValorisationDate, formatMessage, formatDate, renderCommonData]);

  const renderIsSolidaire = useCallback(() => {
    if (!isSolidaire) {
      return null;
    }
    return (
      <>
        <View style={styles.isSolidaireContainer}>
          <View style={styles.isSolidaireIconContainer}>
            <SVGLocalLoader name={'greenLabel'} />
          </View>
          <Title
            style={styles.commonLabelTextStyle}
            variant="t7"
            weight="light"
          >
            {formatMessage({ id: 'repartition_support_solidaire_label' })}
          </Title>
        </View>
      </>
    );
  }, [isSolidaire, formatMessage, styles]);

  const renderSelection = useCallback(() => {
    if (!isSelectable || disabled) {
      return null;
    }

    switch (selectType) {
      case SavingRepartitionCardSelectTypeEnum.CHECKBOX:
        return (
          <CheckBox
            containerStyle={styles.checkBoxStyle}
            onPress={onSelectChange}
            value={isSelectable && isSelected ? isSelected : false}
          />
        );
      case SavingRepartitionCardSelectTypeEnum.RADIO:
        return (
          <RadioButton
            containerStyle={styles.checkBoxStyle}
            onPress={onSelectChange}
            testId={checkboxTestId}
            value={isSelectable && isSelected ? isSelected : false}
          />
        );
      default:
        return null;
    }
  }, [isSelected, selectType, isSelectable, disabled, onSelectChange, styles]);

  return (
    <View style={isStyleAlreadyHandled ? undefined : styles.container}>
      <View style={styles.style}>
        <View style={styles.leftContainer}>
          <View style={styles.headerLineContainer}>
            <View style={styles.leftContainer}>
              <View
                style={styles.checkBoxContainer}
                testID="list_savings_items_length"
              >
                {!isRefund && renderSelection()}
                <Pressable
                  disabled={disabledLink}
                  onPress={disabledLink ? undefined : onCardPress}
                  style={styles.supportNameContainerStyle}
                >
                  <Title
                    style={styles.supportNameStyle}
                    testId={'title_' + testId}
                    variant="t7"
                    weight="bold"
                  >
                    {supportName !== '' &&
                      (drivenSupport ? supportName : supportName?.toUpperCase())}
                    {cardTitle !== '' && cardTitle}
                  </Title>
                </Pressable>
              </View>
              {source === FundSourceEnum.UNKNOWN && (
                <Title
                  style={styles.commonLabelTextStyle}
                  variant="t7"
                  weight="light"
                >
                  {formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_nodata_label' })}
                </Title>
              )}
            </View>
          </View>
          <View style={styles.lowerLineContainer}>
            <View style={{ flex: 1 }}>
              {renderAmountAlreadyInvested()}
              {renderArbitrableAmount()}
              {renderTransferableAmount()}
              {renderAvailableAmount()}
              {renderShareCount()}
              {renderRiskLevel()}
              {renderAddedvalue()}
              {renderPerformance()}
              {renderValorisationDate()}
              {renderNextValorisationDate()}
              {renderIsSolidaire()}
              {children}
            </View>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <InputEditAmountWithLinkAndContribution
            amount={amount}
            contribution={contribution}
            disabled={disabled}
            editable={editable}
            fieldAmount={fieldAmount}
            fieldPercent={fieldPercent}
            isContributionDisplayed={isContributionDisplayed}
            isContributionError={isContributionError}
            isContributionLast={isContributionLast}
            isFieldAmountPercentage={isFieldAmountPercentage}
            isLinkUnderField={isLinkUnderField}
            isLinkUnderfieldAlwaysEnabled={isLinkUnderfieldAlwaysEnabled}
            isPercentagePlusAmount={isFieldPercentagePlusAmount}
            isSelected={isSelected}
            linkUnderfieldLabel={linkUnderfieldLabel}
            onEditAmountPress={onEditAmountPress}
            onLinkUnderFieldPress={onLinkUnderFieldPress}
            onResetAmountPress={onResetAmountPress}
            showSign={false}
            testId={'amount_' + testId}
          />
        </View>
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
