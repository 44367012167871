export const adaptContributions = (
  distributions: any,
  savedDistributions: Object[],
  planId: string
) => {
  let adaptedDistrib;
  const index = savedDistributions.findIndex((obj: any) => obj.planId === planId);
  if (index !== -1) {
    const updatedObject = {
      ...savedDistributions[index],
      distributions: distributions.distributions
    };
    savedDistributions.splice(index, 1);
    adaptedDistrib = [...savedDistributions, updatedObject];
  } else {
    adaptedDistrib = [...savedDistributions, distributions];
  }
  return adaptedDistrib;
};
export const sharingHasMinSupportWithAmountGreaterThanZero = (distributions: any): boolean => {
  return distributions.some(plan =>
    plan.distributions.some(distribution =>
      distribution.supports.some(support => support.amount > 0)
    )
  );
};

export const installmentHasMinSupportWithAmountGreaterThanZero = (distributions: any): boolean => {
  return distributions.some(distribution =>
    distribution.supports.some(support => support.amount > 0)
  );
};
