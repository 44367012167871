import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ExpendableGreen,
  ChequeInfosCard,
  BankAccountInfosCard,
  Paragraph,
  NotificationHard,
  NotificationIconTypeEnum,
  OperationsHistoryStatusEnum,
  useTranslation,
  Title
} from '@components/index';
import { ProfitSharingIncentiveLegalFrameworkEnum } from '@constants/index';
import { OperationDetailsPaymentModeTypeEnum } from '@constants/savings';
import { OperationDetailsDestinationPayment } from '@modules/savings/types';

import { useCards, useData, useLabel } from './hooks';
import {
  FiletStepperCards,
  FiletStepperCardAccounts,
  FiletStepperCardOrigin
} from '../FiletStepperCard';
import { NotificationExternalPlan } from './NotificationExternalPlan';
import { NotificationReminderCancelable } from './NotificationReminderCancelable';
import { useStyles } from './useStyles';
import { label, nonExoticFrames } from '../../label';

export const OperationDetailsContent: React.FC = () => {
  const styles = useStyles();
  const { formatMessage } = useTranslation();
  const {
    companyName,
    deadline,
    remainingDays,
    netAmount,
    originTitleLabelCode,
    source,
    destination,
    statusCode,
    conditionalOrder,
    isCancelable,
    isAmountHidden,
    isIncentive,
    isRefund,
    isArbitration,
    isInstallment,
    applicationSettings,
    isCapitalizedDividend,
    optionsBulletin,
    abundanceNetAmount,
    hasTrueExternalPlan
  } = useData();

  const { getCards } = useCards();
  const { getLabel } = useLabel();

  const renderReminderCancelable = useCallback(() => {
    if (!isCancelable) return;
    return (
      <NotificationReminderCancelable
        deadline={deadline}
        remainingDays={remainingDays}
      />
    );
  }, [isCancelable, deadline, remainingDays]);

  const renderOrigin = useCallback(() => {
    if (netAmount === undefined) return;
    const { defaultInterestAmount } = optionsBulletin ?? {};
    const { type, legalFramework } = source?.bulletinOption ?? {};
    const calculatedFrame = nonExoticFrames.includes(legalFramework || '')
      ? legalFramework!
      : ProfitSharingIncentiveLegalFrameworkEnum.normal;
    const labelId = type && legalFramework && label.interestType[type]?.[calculatedFrame];
    const operationTitle = (labelId && formatMessage({ id: labelId })) || originTitleLabelCode;
    return (
      <FiletStepperCardOrigin
        companyName={companyName}
        defaultInterestAmount={defaultInterestAmount}
        interestTitle={operationTitle}
        netAmount={netAmount}
      />
    );
  }, [netAmount, optionsBulletin, formatMessage, originTitleLabelCode, companyName, source]);

  const renderNotification = useCallback(() => {
    if (statusCode !== OperationsHistoryStatusEnum.IN_PROGRESS || !conditionalOrder?.thresholdType)
      return;

    return (
      <NotificationHard
        containerStyle={styles.notificationHard}
        text={getLabel().notificationInProgress}
        type={NotificationIconTypeEnum.WARNING}
      />
    );
  }, [statusCode, conditionalOrder, styles, getLabel]);

  const renderSource = useCallback(() => {
    if (!source?.plans?.length) return;

    const { cards } = getCards(source?.plans, 'SOURCE');

    return (
      <FiletStepperCards
        cards={cards}
        conditionalOrder={conditionalOrder}
        disableLine={!source?.payment}
        showMoreSupportData={statusCode === OperationsHistoryStatusEnum.TERMINE}
        statusCode={statusCode}
      />
    );
  }, [conditionalOrder, getCards, source, statusCode]);

  const renderDestination = useCallback(() => {
    if (!destination?.plans?.length) return;

    const { cards } = getCards(destination?.plans, 'DESTINATION');
    return (
      <FiletStepperCards
        cards={cards}
        disableLine={!destination?.payment}
        displayFiletTitle={!isCapitalizedDividend}
        showMoreSupportData={statusCode === OperationsHistoryStatusEnum.TERMINE}
      />
    );
  }, [destination, getCards, isCapitalizedDividend]);

  const renderContributions = useCallback(() => {
    if (
      !(abundanceNetAmount && abundanceNetAmount > 0) ||
      (!destination?.planContributions?.length && !source?.planContributions?.length)
    )
      return;
    const contentTitle = formatMessage({
      id: 'DetailOpération_SyntheseDeMonAbonnement'
    });
    const contributionPlans = [
      ...(!!source?.planContributions ? source.planContributions : []),
      ...(!!destination?.planContributions ? destination.planContributions : [])
    ];
    const { cards } = getCards(contributionPlans, 'CONTRIBUTION');
    return (
      <>
        <Title
          style={styles.operationAbundanceTitle}
          variant="t6"
          weight="bold">
          {contentTitle}
        </Title>
        <FiletStepperCards
          cards={cards}
          disableLine={true}
          displayFiletTitle={false}
          showMoreSupportData={statusCode === OperationsHistoryStatusEnum.TERMINE}
          withoutHorizontalMargin={true}
        />
      </>
    );
  }, [
    abundanceNetAmount,
    destination?.planContributions,
    formatMessage,
    getCards,
    source?.planContributions,
    statusCode,
    styles.operationAbundanceTitle
  ]);

  const renderContentTitle = useCallback(() => {
    const contentTitle = formatMessage({
      id: 'PIDetailOperationSyntheseTitle'
    });
    const hasOperationDetail = !!source?.plans?.length || !!destination?.plans?.length;
    return hasOperationDetail ? (
      <Title
        style={styles.operationSummary}
        variant="t6"
        weight="bold">
        {contentTitle}
      </Title>
    ) : null;
  }, [formatMessage, styles.operationSummary]);

  const renderAccounts = useCallback(
    (
      payment: OperationDetailsDestinationPayment | null | undefined,
      position: 'SOURCE' | 'DESTINATION'
    ) => {
      if (!payment) return;

      const label = getLabel(position);

      let PrePaymentDetails: React.ReactNode = null;
      let PaymentDetails: React.ReactNode = null;

      switch (payment.savers.type) {
        case OperationDetailsPaymentModeTypeEnum.CHEQUE:
          const address = payment.savers.fullAddress;
          PrePaymentDetails = position === 'DESTINATION' && (
            <>
              <Paragraph
                variant="t3"
                weight="light">
                {label[OperationDetailsPaymentModeTypeEnum.CHEQUE].prePaymentDetailsTitle}
              </Paragraph>
              <Paragraph
                autoMargin
                variant="t3"
                weight="light">
                {label[OperationDetailsPaymentModeTypeEnum.CHEQUE].prePaymentDetailsSubtitle}
              </Paragraph>
            </>
          );
          PaymentDetails = (
            <ExpendableGreen
              title={label[OperationDetailsPaymentModeTypeEnum.CHEQUE].paymentDetailsTitle}>
              <View style={styles.paymentDetailContainer}>
                <ChequeInfosCard
                  appSettings={applicationSettings}
                  chequeInfoData={{
                    ...address,
                    additionalAddress_2: address.additionalAddress_2 || '',
                    additionalAddress_3: address.additionalAddress_3 || '',
                    street: address.street || '',
                    recipient: address.recipient || ''
                  }}
                />
                {position === 'DESTINATION' && (
                  <Paragraph
                    variant="t3"
                    weight="light">
                    {label[OperationDetailsPaymentModeTypeEnum.CHEQUE].paymentDetailsSubtitle}
                  </Paragraph>
                )}
              </View>
            </ExpendableGreen>
          );
          break;
        case OperationDetailsPaymentModeTypeEnum.VIREMENT:
          const bankAccountDetail = payment.savers.bankAccountDetail;
          if (!bankAccountDetail.iban) break;
          PrePaymentDetails = position === 'DESTINATION' && (
            <>
              <Paragraph
                variant="t3"
                weight="light">
                {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].prePaymentDetailsTitle}
              </Paragraph>
              <Paragraph
                autoMargin
                variant="t3"
                weight="light">
                {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].prePaymentDetailsSubtitle}
              </Paragraph>
            </>
          );
          PaymentDetails = (
            <ExpendableGreen
              title={label[OperationDetailsPaymentModeTypeEnum.VIREMENT].paymentDetailsTitle}>
              <View style={styles.paymentDetailContainer}>
                <BankAccountInfosCard
                  AccountInfoData={{
                    ...bankAccountDetail,
                    domiciliation: bankAccountDetail?.domiciliation || undefined,
                    titulaire: bankAccountDetail?.titulaire || undefined
                  }}
                  NoDisplayPaymentChoice
                />
                <Paragraph
                  autoMargin
                  variant="t3"
                  weight="light">
                  {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].paymentDetailsSubtitle1}
                </Paragraph>
                {position === 'DESTINATION' && (
                  <>
                    <Paragraph
                      variant="t3"
                      weight="light">
                      {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].paymentDetailsSubtitle2}
                    </Paragraph>
                  </>
                )}
              </View>
            </ExpendableGreen>
          );
          break;
        default:
          break;
      }

      return (
        <FiletStepperCardAccounts
          amount={payment?.amount || 0}
          description={label[payment.savers.type]?.title || ''}
          disableLine={position !== 'SOURCE'}
          hideAmount={isAmountHidden}
          stepTitle={label.stepTitle}
          title={label[payment.savers.type]?.description || ''}>
          {(!!PrePaymentDetails || !!PaymentDetails) && (
            <View style={styles.bankAccountContainer}>
              {(isIncentive || isRefund) && PrePaymentDetails}
              {PaymentDetails}
            </View>
          )}
        </FiletStepperCardAccounts>
      );
    },
    [getLabel, isAmountHidden, styles, isIncentive, isRefund, applicationSettings]
  );

  const renderNotificationExternalPlan = useCallback(() => {
    if (!hasTrueExternalPlan) return;
    return <NotificationExternalPlan />;
  }, [hasTrueExternalPlan]);

  return (
    <>
      {renderContentTitle()}
      {isIncentive && (
        <View style={styles.notificationsContainer}>
          {renderNotificationExternalPlan()}
          {renderReminderCancelable()}
        </View>
      )}
      {(isRefund || isArbitration) && renderNotification()}
      {isIncentive && renderOrigin()}
      {(isRefund || isArbitration) && renderSource()}
      {isInstallment && renderAccounts(source?.payment, 'SOURCE')}
      {!isRefund && renderDestination()}
      {(isIncentive || isRefund) && renderAccounts(destination?.payment, 'DESTINATION')}
      {renderContributions()}
    </>
  );
};
