import { useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = (addedValue?: number, valueStyle?: StyleProp<TextStyle>) => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        addedValueStyle: {
          textAlign: 'center',
          ...StyleSheet.flatten(valueStyle),
          color:
            addedValue && addedValue < 0
              ? theme.colors.basics.message.error.c500
              : theme.colors.basics.primary.c500
        }
      }),
    [addedValue, theme, valueStyle]
  );

  return styles;
};
