import { PlanFamillyList } from '@constants/common';
import { ErrorObjectState } from '@modules/error/types';

export interface DashboardErrors {
  availabilitiesError?: AccountsErrorState;
  allocationsError?: AccountsErrorState;
  OCDError?: AccountsErrorState;
  recentOperationsError?: AccountsErrorState;
  toolsError?: AccountsErrorState;
  contributionError?: AccountsErrorState;
  budgetInsightError?: BudgetInsightErrorType;
  budgetInsightGetUrlError?: BudgetInsightErrorType;
}

export interface AccountsErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface AccountState {
  companyName: string;
  companyId: string;
  dateAmount: string;
  accountNumber: string;
  totalAmount: number;
  totalAddedValue: number;
  isActiveAccount: boolean;
  isBudgetInsightEligible: boolean;
  isES?: boolean;
  isRC?: boolean;
  plans: AllocationState[];
}

export interface AvailabilitiesState {
  immediateAmount: number;
  midTermAmount: number;
  longTermAmount: number;
  earlyReleaseAmount: number;
}

export interface AllocationsState {
  plans: AllocationState[];
  name: string;
  label: string;
  description: string;
}

export interface AllocationState {
  colors: string[];
  planID: string;
  shortName: string;
  name: string;
  totalAmount: number;
  totalAddedValue: number;
  isCustomPlan: boolean;
  type?: string;
  totalNetEstimatedAmount?: number;
  subType?: string;
  dateAddedValue?: string;
  planFamily?: PlanFamillyList;
}

export interface AccountsState {
  totalAmount: number;
  totalAddedValue: number;
  dateAmount: string | null; // totalAmountAt
  companies: AccountState[];
}

export interface SavingsPlansState {
  planId: string;
  title: string;
  planFamily: string;
  titleLabelCode?: string;
  totalAmount: number | undefined;
  totalAddedValue: number | undefined;
  estimatedNetAmount?: number;
  isEstimatedBeforeTax?: boolean;
  dateAddedValue?: string;
  type?: string;
}

export interface ToolState {
  title: string;
  url: string;
  content: string;
  buttonText: string;
  pictureUrl: string;
  operationType?: string;
  flows?: string[];
}

export interface OCDState {
  contenu: string;
  appContenu: string;
  backgroundColor: string;
  position: number;
  image1SmallUrl: string;
  image1LargeUrl: string;
  image2SmallUrl: string;
  image2LargeUrl: string;
}

export interface RecentOperationsState {
  id: string;
  code: string;
  label: string;
  type: string;
  statusCode: string;
  statusLabel: string;
  amount: number;
  dateTime: string;
  compartimentId: string;
  frontDisplayed: string;
  planId: string;
}

export interface DashboardRepartitionPlanType {
  value: number;
  label: string;
  color: string;
}

export interface PlanContributionsType {
  planId: number;
  planName: string;
  consumed: number;
  limit: number;
  balance: number;
}

export interface ContributionByCompanyType {
  companyId: number;
  totalLimit: number;
  totalConsumed: number;
  totalBalanced: number;
  planContributions: PlanContributionsType[];
}
export interface ContributionPlanType {
  plan: string;
  amount: number;
  contributionAmount: number;
}

export interface ContributionOperationType {
  hasOptimization: boolean;
  hasJourneyAvailable: boolean;
  hasContribution: boolean;
  operations: ContributionPlanType[];
}

export interface ContributionByOperationType {
  hasOptimization: boolean;
  payment: ContributionOperationType | null;
  incentive: ContributionOperationType | null;
  profitSharing: ContributionOperationType | null;
}

export type BiAccountsState = {
  id: number;
  name: string;
  balance: number;
  iban: string;
};
export type BudgetInsightState = {
  id: number;
  name: string;
  cumulativeBalance: number;
  accounts: BiAccountsState[];
};
export type BudgetInsightErrorType = {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
};

export interface CibledContributionContentState {
  code: string;
  content: string;
}
export interface CibledContributionErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface DashboardState {
  userAccounts: AccountsState | undefined;
  selectedCompany: AccountState | undefined;
  availabilities: AvailabilitiesState | undefined;
  allocations: AllocationsState[] | undefined;
  allPlans: DashboardRepartitionPlanType[];
  savingsPlans: SavingsPlansState[];
  tools: { [key: string]: ToolState[] };
  OCDTop: OCDState[];
  OCDBottom: OCDState[];
  recentOperations: RecentOperationsState[];
  error: AccountsErrorState;
  availabilitiesError: AccountsErrorState;
  allocationsError: AccountsErrorState;
  OCDError: AccountsErrorState;
  recentOperationsError: AccountsErrorState;
  toolsError: AccountsErrorState;
  contributionByCompany: ContributionByCompanyType | null;
  contributionByOperation: ContributionByOperationType | null;
  contributionCibledContent: CibledContributionContentState | null;
  contributionCibledError: CibledContributionErrorState;
  contributionError: AccountsErrorState;
  budgetInsightUrl: string;
  budgetInsightManagerUrl: string;
  budgetInsightAccounts: BudgetInsightState[];
  budgetInsightError: BudgetInsightErrorType;
  budgetInsightGetUrlError: BudgetInsightErrorType;
  ssoUrl: string;
  ssoUrlError: ErrorObjectState | undefined;
}
export enum ToolsFlowTypeEnum {
  RETIREMENT = 'RETIREMENT',
  DASHBOARD = 'DASHBOARD'
}
