import { ProfitSharingIncentiveLegalFrameworkEnum, ProfitSharingIncentiveTypeEnum } from '@constants/index';
import {
  ProfitSharingIncentiveActionsType
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';
export enum ProfitSharingIncentiveNavigatorRouteNames {
  ProfitSharingIncentiveAssigment = 'ProfitSharingIncentiveAssigment',
  ProfitSharingIncentiveDescription = 'ProfitSharingIncentiveDescription',
  ProfitSharingIncentiveOnboarding = 'ProfitSharingIncentiveOnboarding',
  ProfitSharingIncentiveRepartition = 'ProfitSharingIncentiveRepartition',
  ProfitSharingIncentiveSynthesis = 'ProfitSharingIncentiveSynthesis',
  ProfitSharingIncentiveDefaultAffectations = 'ProfitSharingIncentiveDefaultAffectations',
  ProfitSharingIncentiveSuccess = 'ProfitSharingIncentiveSuccess',
  ProfitSharingIncentiveModal = ' ProfitSharingIncentiveModal',
  ProfitSharingIncentiveFailedPage = ' ProfitSharingIncentiveFailedPage',

}

export type ProfitSharingIncentiveNavigatorInterface = {
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveDescription]: {
    type : ProfitSharingIncentiveTypeEnum,
    cadre : ProfitSharingIncentiveLegalFrameworkEnum,
    transactionId?: string
  };
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveAssigment]: {
    transactionId?: string;
  };
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveOnboarding]: {
    transactionId?: string;
  };
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveRepartition]: undefined;
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveSynthesis]: undefined;
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveSuccess]: undefined;
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveDefaultAffectations]: undefined;
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveModal]: {
    clearAction: ProfitSharingIncentiveActionsType.CLEAR_PROFIT_SHARING_INCENTIVE_DATA
  };
  [ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveFailedPage]: undefined;
}
