import { orderBy } from 'lodash';

import { estimatedNetBeforeTaxPlansTypes } from '@modules/common/constants';

import {
  AccountState,
  AllocationsState,
  ContributionByCompanyType,
  DashboardRepartitionPlanType,
  DashboardState,
  SavingsPlansState
} from './types';

const PLAN_TYPE_EXTERNE = 'EXT';
export const setAllPlans = (allocations: AllocationsState[]) => {
  let allPlans: DashboardRepartitionPlanType[] = [];
  allocations?.forEach(allocation => {
    allocation.plans?.forEach(plan => {
      allPlans.push({
        label: plan.shortName,
        color: plan.colors[0],
        value: plan.totalAmount
      });
    });
  });
  allPlans = orderBy(allPlans, ['value'], ['desc']);
  return allPlans;
};
export const filterExternalPlans = (allocations: AllocationsState[]) => {
  const filteredAllocation: AllocationsState[] = [];
  allocations?.forEach(allocation => {
    const filtredPlans = allocation.plans?.filter(plan => plan.type !== PLAN_TYPE_EXTERNE);
    filteredAllocation.push({ ...allocation, plans: filtredPlans });
  });
  return filteredAllocation;
};
export const distributeAllocations = (allocations: AllocationsState[], state?: DashboardState) => {
  const result: SavingsPlansState[] = [];
  allocations.forEach((item: any) => {
    item.plans.forEach((element: any) => {
      result.push({
        planId: element.planID,
        title: element.shortName,
        totalAmount: element.totalAmount,
        totalAddedValue: element.totalAddedValue,
        isEstimatedBeforeTax: estimatedNetBeforeTaxPlansTypes.includes(element.type || ''),
        estimatedNetAmount: element.totalNetEstimatedAmount,
        planFamily: element.planFamily,
        dateAddedValue: element.dateAddedValue,
        type: element.type
      });
    });
  });
  if (result.length > 1) {
    result.unshift({
      planId: 'Account',
      title: '',
      titleLabelCode: 'saving_title',
      totalAmount: state?.selectedCompany?.totalAmount,
      totalAddedValue: state?.selectedCompany?.totalAddedValue,
      planFamily: '',
      type: ''
    });
  }
  return result;
};

export const handleContributionByCompany = (
  contribution: ContributionByCompanyType | null
): ContributionByCompanyType | null => {
  if (contribution?.planContributions?.length !== 1) return contribution;

  const {
    consumed: totalConsumed = 0,
    balance: totalBalanced = 0,
    limit: totalLimit = 0
  } = contribution?.planContributions?.[0];

  return {
    ...contribution,
    totalConsumed,
    totalBalanced,
    totalLimit
  };
};

export const getActiveCompanies = (companies: AccountState[] | null | undefined) =>
  (companies || []).reduce<AccountState[]>((activeCompanies, company, index, array) => {
    if (company?.isActiveAccount || company?.totalAmount) return [...activeCompanies, company];
    if (index === array.length - 1 && !activeCompanies.length) return array;
    return activeCompanies;
  }, []);
