import { useMemo } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';
import { PlatformOS } from '@modules/common/types';

import { StylesProps } from './interfaces';

export const useStyles = (hasAddedValueOrNetEsimatedAmountAndHelpPoint, style?: StylesProps) => {
  const theme = useTheme();

  const isMobile = Platform.OS !== PlatformOS.WEB;

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: theme.colors.basics.white,
          minHeight: isMobile && hasAddedValueOrNetEsimatedAmountAndHelpPoint ? 200 : 156,
          ...StyleSheet.flatten(style?.container)
        }
      }),
    [style, theme, isMobile, hasAddedValueOrNetEsimatedAmountAndHelpPoint]
  );

  return styles;
};
