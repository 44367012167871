import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';

import { DashboardPageWrapperConnected, useTranslation } from '@components/index';
import { innerNavigations } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import {
  FilterRoulesState,
  RepartitionFiltersState,
  SavingsHistoryFiltersState,
  SavingsHistory
} from '@modules/savings/types';

import { SavingsInnerPage } from './components/SavingsInnerPage';
import { SavingProps as Props } from './interfaces';
import { getStyles } from './styles';

export const Saving: React.FC<Props> = ({
  repartitions,
  savingsPlans,
  availabilitiesDetail,
  availabilitiesFunds,
  onGetRepartitionsByPlan,
  savingsHistory,
  operationsHistory,
  onGetRepartitions,
  onGetOperationsHistory,
  selectedCompanyId,
  onGetOperationsHistoryByPlan,
  onGetAvailabilitiesDetail,
  onGetAvailabilitiesDetailByPlan,
  onGetAvailabilitiesFunds,
  onGetAvailabilitiesFundsByPlan,
  onGetSavingsHistoryByPlan,
  onGetSavingsHistory,
  isRepartitionsApiLoading,
  isRepartitionsByPlansApiLoading,
  isOperationsHistoryApiLoading,
  isOperationsHistoryByPlansApiLoading,
  isAvailabilitiesApiLoading,
  isAvailabilitiesByPlansApiLoading,
  isInstallmentApiLoading,
  isSavingsEvolutionLoading,
  isSavingsEvolutionByPlanLoading,
  selectedTab,
  selectedPlan,
  installments,
  appliedOperationsHistoryFilterRoules,
  getInstallments,
  savingsHistoryFilters,
  appSettings,
  hasOnGoingVVPHistory,
  getOnGoingVVPHistory,
  onApplySavingsHistoryFilter,
  onRedirectToSavings,
  onRepartitionFilterChange,
  isFetchingOnGoingVVP
}: Props) => {
  const theme = useTheme();
  const { formatMessage, getMessageRaw } = useTranslation();
  const navigation = useNavigation();

  useFocusEffect(
    useCallback(() => {
      getOnGoingVVPHistory(selectedPlan);
    }, [getOnGoingVVPHistory])
  );

  useEffect(() => {
    return () => {
      onRedirectToSavings(0, 'disposition-tabs1_label');
    };
  }, []);
  const styles = useStyles({ theme }, {});

  const onGetConsolidedData = () => {
    const consolidedOperationHistory = operationsHistory.filter(
      operation => operation.planId === 'Account'
    );
    selectedCompanyId && repartitions.length <= 0 && onGetRepartitions(selectedCompanyId),
      selectedCompanyId &&
        consolidedOperationHistory.length <= 0 &&
        onGetOperationsHistory(selectedCompanyId),
      selectedCompanyId &&
        availabilitiesDetail.length <= 0 &&
        onGetAvailabilitiesDetail(selectedCompanyId),
      selectedCompanyId &&
        availabilitiesFunds.length <= 0 &&
        onGetAvailabilitiesFunds(selectedCompanyId);
    selectedCompanyId && savingsHistory.length <= 0 && onGetSavingsHistory();
  };
  // check if we need to call api while plan or tab change
  useEffect(() => {
    const isAccountPlanSelected = !!savingsPlans?.length && Number(selectedPlan) === 0;
    const isOperationHistoryTab = selectedTab === innerNavigations[3];

    if (!isAccountPlanSelected) {
      getActionByPlan(selectedTab);
    } else if (isOperationHistoryTab) {
      onGetSavingsHistory();
      onGetOperationsHistoryByPlan(selectedPlan);
    } else {
      onGetConsolidedData();
    }
  }, [selectedPlan, selectedTab]);

  // manage redux savings plan and tab init and changes
  useEffect(() => {
    onTabChange(selectedTab);
  }, [selectedTab]);

  const swipeLeft = () => {
    onRedirectToSavings(selectedPlan - 1, selectedTab);
  };

  const swipeRight = () => {
    onRedirectToSavings(selectedPlan + 1, selectedTab);
  };

  const onTabChange = (tabId: string) => {
    onRedirectToSavings(selectedPlan, tabId);
  };
  const checkIfGetSavingsHistoryCanBeCalled = (
    dataToCheck: any,
    selectedUsedPlan: number = selectedPlan,
    filter?: SavingsHistoryFiltersState
  ) => {
    const filtredHistorySavingsByPlanId = dataToCheck.filter(
      (item: any) => item.planId === savingsPlans[selectedUsedPlan]?.planId
    );
    if (filter) {
      const filtredSavingsHistory = filtredHistorySavingsByPlanId[0]?.allHistory?.filter(
        (savingHistoryByPeriod: any) => savingHistoryByPeriod.period === filter.period
      );
      return (
        filtredHistorySavingsByPlanId.length <= 0 ||
        !filtredSavingsHistory ||
        filtredSavingsHistory.length <= 0
      );
    }
    return filtredHistorySavingsByPlanId.length <= 0;
  };

  const getActionByPlan = (tabId?: string) => {
    switch (tabId) {
      case innerNavigations[0]: {
        onGetRepartitionsByPlan(selectedPlan);
        return;
      }
      case innerNavigations[1]: {
        onGetAvailabilitiesDetailByPlan(selectedPlan);
        onGetAvailabilitiesFundsByPlan(selectedPlan);
        return;
      }
      case innerNavigations[3]: {
        onGetOperationsHistoryByPlan(selectedPlan);
        return;
      }
      case innerNavigations[2]: {
        checkIfGetSavingsHistoryCanBeCalled(savingsHistory, selectedPlan, savingsHistoryFilters) &&
          onGetSavingsHistoryByPlan(selectedPlan);
        return;
      }
      default:
        return;
    }
  };

  const onSetRepartitionFilters = (repartitionFilters: RepartitionFiltersState) => {
    onRepartitionFilterChange(repartitionFilters);
  };

  const currentElements = (elements: any[]) => {
    if (elements.length === 1 && elements[0]?.planId === 'Account') {
      return elements[0]?.items;
    }

    return elements.filter(
      item => item?.planId.toString() === savingsPlans[selectedPlan]?.planId.toString()
    )[0]?.items;
  };
  const currentElementsOfSavingsHistory = (elements: SavingsHistory[]) => {
    const allSavingsHistoryByPeriod: SavingsHistory = elements.filter(
      item => item?.planId === savingsPlans[selectedPlan]?.planId
    )[0];
    return allSavingsHistoryByPeriod?.allHistory?.filter(
      savingHistoryByPeriod => savingHistoryByPeriod.period === savingsHistoryFilters.period
    )[0]?.items;
  };

  const handleOperationsHistoryFilterChange = (filterRoules: FilterRoulesState) => {
    const isConsolidedAccount = savingsPlans?.length > 1 && selectedPlan === 0;
    if (isConsolidedAccount && selectedCompanyId) {
      return onGetOperationsHistory(selectedCompanyId, filterRoules || undefined);
    }
    onGetOperationsHistoryByPlan(selectedPlan, filterRoules || undefined);
  };

  const onApplySavingsHistoryFilters = (newSavingsHistoryFilters: SavingsHistoryFiltersState) => {
    onApplySavingsHistoryFilter(newSavingsHistoryFilters);
    const shouldBeCalled = checkIfGetSavingsHistoryCanBeCalled(
      savingsHistory,
      selectedPlan,
      newSavingsHistoryFilters
    );
    // manage the case we have only one plan and we have not to show consolided plan (account) => savingsPlans.length <= 1
    if (savingsPlans.length <= 1 || selectedPlan !== 0) {
      if (shouldBeCalled) {
        return onGetSavingsHistoryByPlan(selectedPlan, newSavingsHistoryFilters);
      }
    } else if (shouldBeCalled) {
      return onGetSavingsHistory(newSavingsHistoryFilters);
    }
  };

  const handlePressVVPManagement = useCallback(() => {
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.SavingsStack,
      params: {
        screen: RouteNames.VVPList
      }
    });
  }, [navigation]);

  const headerTitleKey = savingsPlans[selectedPlan]?.titleLabelCode
    ? formatMessage({ id: savingsPlans[selectedPlan]?.titleLabelCode })
    : savingsPlans[selectedPlan]?.title;

  const helpPoint = useMemo(
    () => ({
      contentHtml: getMessageRaw({
        id: 'Dashboard_CardRepartition_info_content',
        defaultMessage: ' '
      }),
      contentTitle: formatMessage({
        id: 'Dashboard_CardRepartition_info_content_title',
        defaultMessage: ' '
      }),
      modalTitle: formatMessage({ id: 'NEAideTitle' })
    }),
    [formatMessage, getMessageRaw]
  );

  const renderContent = () => (
    <View style={styles.container}>
      <View style={styles.fullWidthContainer}>
        <SavingsInnerPage
          addedValue={savingsPlans[selectedPlan]?.totalAddedValue}
          amount={savingsPlans[selectedPlan]?.totalAmount}
          appliedOperationsFilterRoules={appliedOperationsHistoryFilterRoules}
          appSettings={appSettings}
          availabilitiesDetail={currentElements(availabilitiesDetail)}
          availabilitiesFunds={currentElements(availabilitiesFunds)}
          dateAddedValue={savingsPlans[selectedPlan]?.dateAddedValue}
          estimatedNetAmount={savingsPlans[selectedPlan]?.estimatedNetAmount}
          getInstallments={getInstallments}
          hasOnGoingVVPHistory={hasOnGoingVVPHistory}
          helpPoint={
            savingsPlans[selectedPlan]?.titleLabelCode === 'saving_title' ? undefined : helpPoint
          }
          innerNavigations={innerNavigations}
          installments={installments}
          isAvailabilitiesApiLoading={isAvailabilitiesApiLoading}
          isAvailabilitiesByPlansApiLoading={isAvailabilitiesByPlansApiLoading}
          isEstimatedBeforeTax={savingsPlans[selectedPlan]?.isEstimatedBeforeTax}
          isFetchingOnGoingVVP={isFetchingOnGoingVVP}
          isInstallmentApiLoading={isInstallmentApiLoading}
          isOperationsHistoryApiLoading={isOperationsHistoryApiLoading}
          isOperationsHistoryByPlansApiLoading={isOperationsHistoryByPlansApiLoading}
          isRepartitionsApiLoading={isRepartitionsApiLoading}
          isRepartitionsByPlansApiLoading={isRepartitionsByPlansApiLoading}
          isSavingsEvolutionByPlanLoading={isSavingsEvolutionByPlanLoading}
          isSavingsEvolutionLoading={isSavingsEvolutionLoading}
          onApplySavingsHistoryFilters={onApplySavingsHistoryFilters}
          onPressFilter={handleOperationsHistoryFilterChange}
          onPressVVPManagement={handlePressVVPManagement}
          onSetRepartitionFilters={onSetRepartitionFilters}
          onTabChange={tabId => {
            onTabChange(tabId);
          }}
          operationsHistory={currentElements(operationsHistory)}
          planId={selectedPlan === 0 ? undefined : savingsPlans[selectedPlan]?.planId}
          planName={selectedPlan === 0 ? 'consolide' : savingsPlans[selectedPlan]?.title}
          repartitions={currentElements(repartitions)}
          savingPlans={savingsPlans}
          savingsHistory={currentElementsOfSavingsHistory(savingsHistory)}
          savingsHistoryFilters={savingsHistoryFilters}
          selectedPlan={selectedPlan}
          selectedTab={selectedTab}
          swipeLeft={() => swipeLeft()}
          swipeRight={() => swipeRight()}
          titleKey={headerTitleKey}
        />
      </View>
    </View>
  );

  return <DashboardPageWrapperConnected>{renderContent()}</DashboardPageWrapperConnected>;
};

const useStyles = createUseStyles(getStyles);

export default Saving;
