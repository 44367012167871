import { StyleProp, TextStyle } from 'react-native';

import { Text } from '@ere-uilib/atoms/texts';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './useStyles';
interface Props {
  addedValue?: number;
  labelKey: string;
  testId: string;
  dateAddedValue?: string;
  valueStyle?: StyleProp<TextStyle>;
}

export const PlusMinusValue: React.FC<Props> = ({
  addedValue,
  testId,
  valueStyle,
  labelKey,
  dateAddedValue
}) => {
  const styles = useStyles(addedValue, valueStyle);
  const { formatCurrencyNumber, formatMessage, formatDate } = useTranslation();
  if (addedValue === null || addedValue === undefined) return null;

  const displayedAmount = !!dateAddedValue
    ? formatMessage({
        id: 'Dashboard_CardRepartition_RC_Item_value_label',
        values: {
          date: formatDate({
            value: dateAddedValue
          }),
          montant: formatCurrencyNumber({
            value: addedValue || 0
          })
        }
      })
    : labelKey === 'Dashboard_CardRepartition_Item_value_label'
      ? formatMessage({
          id: labelKey,
          values: { variable: formatCurrencyNumber({ value: addedValue }) }
        })
      : `${formatMessage({ id: labelKey })} ${formatCurrencyNumber({
          value: addedValue || 0
        })}`;
  return (
    <Text
      accessibilityLabel={displayedAmount}
      accessibilityRole="text"
      aria-label={displayedAmount}
      style={styles.addedValueStyle}
      testId={testId}
      variant="t4">
      {displayedAmount}
    </Text>
  );
};
