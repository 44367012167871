import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { CompaignCard } from '@components/ERE360Components/CompaignCard';
import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  Link,
  NotificationHard,
  NotificationIconTypeEnum,
  RetryActionPlaceHolder,
  SimpleButton,
  Spinner,
  Text,
  useScreenSizes,
  useTranslation
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';
import {
  ProfitSharingIncentiveNavigatorInterface,
  ProfitSharingIncentiveNavigatorRouteNames
} from '@navigation/Interfaces';

import { ProfitSharingIncentiveHeader } from '../components';
import { compaignOperationType } from './compaignOperationType';
import { PrimeAmountCard } from './components';
import { ProfitSharingIncentiveDescriptionPageProps } from './interface';
import { label } from './label';
import { useStyles } from './styles';

export const ProfitSharingIncentiveDescriptionPageComponent: React.FC<
  React.PropsWithChildren<ProfitSharingIncentiveDescriptionPageProps>
> = ({
  navigation,
  initData,
  campaignData,
  getProfitSharingIncentiveInitData,
  getProfitSharingIncentiveCompaignData,
  isLoading,
  error,
  errorCampaignData
}) => {
  const route =
    useRoute<
      RouteProp<
        ProfitSharingIncentiveNavigatorInterface,
        ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveDescription
      >
    >();

  const {
    type: profitSharingIncentiveType,
    cadre: profitSharingIncentiveLegalFramework,
    transactionId
  } = route?.params ?? {};
  const styles = useStyles();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage, getMessageRaw, formatDate } = useTranslation();

  const isDerogatedAffectationsAvailable = useMemo(
    () => (initData?.derogatoryAmount || 0) > 0,
    [initData]
  );

  const helpPointContentHtml = useMemo(() => {
    const { content, derogatoryAmountLabel } = label.header.help;
    const isProfitSharing =
      profitSharingIncentiveType === ProfitSharingIncentiveTypeEnum.profitsharing;

    if (isProfitSharing && isDerogatedAffectationsAvailable) {
      return derogatoryAmountLabel;
    }
    return (
      content[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
      content[profitSharingIncentiveType]?.default
    );
  }, [isDerogatedAffectationsAvailable, label.header.help]);

  const handleOnSubmitPress = useCallback(() => {
    navigation.navigate(RouteNames.ProfitSharingIncentive, {
      screen: RouteNames.ProfitSharingIncentiveOnboarding,
      params: {transactionId }
    });
  }, [navigation]);

  const handleContributionCampaignByType = () => {
    const operationType =
      compaignOperationType[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
      compaignOperationType[profitSharingIncentiveType]?.default ||
      '';
    getProfitSharingIncentiveCompaignData(operationType);
  };

  const getInitData = useCallback(() => {
    getProfitSharingIncentiveInitData(
      profitSharingIncentiveType,
      profitSharingIncentiveLegalFramework,
      transactionId
    );
    handleContributionCampaignByType();
  }, [
    getProfitSharingIncentiveInitData,
    profitSharingIncentiveLegalFramework,
    profitSharingIncentiveType
  ]);

  const renderBottomActions = useCallback(() => {
    return (
      <View style={styles.bottomActionsStyle}>
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          loading={isLoading}
          onPress={handleOnSubmitPress}
          testId={locator._pi._lets_go}
          title={formatMessage({ id: label.button.next })}
        />
      </View>
    );
  }, [formatMessage, handleOnSubmitPress, isLoading, styles]);

  const renderAlert = useCallback(() => {
    const description =
      label.alert.description[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
      label.alert.description[profitSharingIncentiveType]?.default;

    const isLinkHidden =
      profitSharingIncentiveType === ProfitSharingIncentiveTypeEnum.incentive &&
      profitSharingIncentiveLegalFramework === ProfitSharingIncentiveLegalFrameworkEnum.ppv;

    return (
      <NotificationHard
        containerStyle={styles.notificationContainerStyle}
        type={NotificationIconTypeEnum.WARNING}>
        <Text>
          {!!initData?.deadlineDate &&
            formatMessage({
              id: label.alert.title,
              values: {
                date_fin: formatDate({ value: new Date(initData.deadlineDate) })
              }
            })}
          <Text weight="light">
            {formatMessage({ id: description })}{' '}
            {!isLinkHidden && (
              <Link
                onPress={() =>
                  navigation.navigate(RouteNames.ProfitSharingIncentive, {
                    screen: RouteNames.ProfitSharingIncentiveDefaultAffectations
                  })
                }>
                {formatMessage({ id: label.alert.link })}
              </Link>
            )}
          </Text>
        </Text>
      </NotificationHard>
    );
  }, [
    profitSharingIncentiveType,
    profitSharingIncentiveLegalFramework,
    styles.notificationContainerStyle,
    initData,
    formatMessage,
    formatDate,
    navigation
  ]);

  const renderHeader = useCallback(() => {
    return (
      <ProfitSharingIncentiveHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({
          id: helpPointContentHtml
        })}
        helpPointModalTitle={formatMessage({ id: label.header.help.title })}
        title={formatMessage({
          id:
            label.header.title[profitSharingIncentiveType]?.[
              profitSharingIncentiveLegalFramework
            ] || label.header.title[profitSharingIncentiveType]?.default
        })}
      />
    );
  }, [
    formatMessage,
    getMessageRaw,
    profitSharingIncentiveLegalFramework,
    profitSharingIncentiveType
  ]);

  const renderError = useCallback(() => {
    return <RetryActionPlaceHolder retryAction={getInitData} />;
  }, [getInitData]);

  const renderContent = useCallback(() => {
    if (error?.message) return renderError();

    if (!initData) return;

    const htmlContent = campaignData?.content;

    return (
      <ScrollView style={styles.pageContainer}>
        {!!campaignData && (
          <CompaignCard
            hasError={!!errorCampaignData?.message}
            html={htmlContent}
            retryAction={handleContributionCampaignByType}
          />
        )}
        <PrimeAmountCard
          companyAmount={initData.totalAmount}
          derogatoryAmount={initData.derogatoryAmount}
          endDate={new Date(initData.fiscalYearEndDate)}
          primeAmount={initData.netShareAmount}
          startDate={new Date(initData.fiscalYearStartDate)}
          style={styles.premiumAmountCard}
        />
        {renderAlert()}
        {!isMobile && !isTablet && renderBottomActions()}
      </ScrollView>
    );
  }, [error, renderError, initData, styles, renderAlert, isMobile, isTablet, renderBottomActions]);

  useFocusEffect(getInitData);

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderBottomActions}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}>
        {isLoading && <Spinner />}
        {!isLoading && renderContent()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
