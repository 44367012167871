import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { InstallmentState } from '@modules/installment/types';
import {
  buildEditVVPData,
  installmentDecisionDataFormatter,
  updateInstallmentRepartitionAmountsData
} from '@modules/installment/utils';
import { backToQuestion, updateQuestionWhenResponded } from '@modules/utils/requirementDefinition';

import { InstallmentAction, InstallmentActionsType } from './actions/installmentActionsTypes';

const initialState: InstallmentState = {
  decision: {
    dispositifs: [],
    questions: [],
    selectedDispositif: [],
    elligibleDispositifs: []
  },
  editedVVPData: undefined,
  paymentMode: null,
  paymentModeData: [],
  repartitionData: null,
  initRepartitionData: null,
  repartitionAmountModalVisibleState: false,
  calculatedContribution: null,
  paymentResponseData: null,
  calculatedContributionError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
};

export function installmentReducer(
  state: InstallmentState = initialState,
  action: InstallmentAction | AuthenticateAction
): InstallmentState {
  switch (action?.type) {
    case InstallmentActionsType.GET_INSTALLMENT_DECISION_REQUEST:
      return {
        ...state,
        decision: initialState.decision
      };
    case InstallmentActionsType.GET_INSTALLMENT_DECISION_SUCCESS:
      return {
        ...state,
        decision: installmentDecisionDataFormatter(action.decision)
      };
    case InstallmentActionsType.UPDATE_QUESTION_WITH_SET_RESPONSE:
      return {
        ...state,
        decision: {
          ...state.decision,
          ...updateQuestionWhenResponded(state, action.response, action.index, action.canNotBeAsked)
        }
      };
    case InstallmentActionsType.BACK_TO_QUESTION:
      return {
        ...state,
        decision: {
          ...state.decision,
          ...backToQuestion(state, action.index)
        }
      };
    case InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_REQUEST:
      return {
        ...state,
        repartitionAmountModalVisibleState: false,
        repartitionData: initialState.repartitionData
      };
    case InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_SUCCESS:
      return {
        ...state,
        repartitionData: action.installmentRepartitionData || null,
        initRepartitionData: action.installmentRepartitionData || null
      };
    case InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE:
      return {
        ...state,
        repartitionAmountModalVisibleState: action.state
      };
    case InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_REQUEST:
      return {
        ...state
      };
    case InstallmentActionsType.SET_ELIGIBLE_DISPOSITIFS:
      return {
        ...state,
        decision: {
          ...state.decision,
          elligibleDispositifs: action.elligibleDispositifs
        }
      };
    case InstallmentActionsType.SET_SELECTED_DISPOSITIF:
      return {
        ...state,
        decision: {
          ...state.decision,
          selectedDispositif: action.selectedDispositif
        },
        editedVVPData: initialState.editedVVPData,
        repartitionData: initialState.repartitionData,
        paymentMode: initialState.paymentMode,
        paymentModeData: initialState.paymentModeData
      };
    case InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_SUCCESS:
      return {
        ...state,
        repartitionAmountModalVisibleState: false,
        repartitionData: action.shouldResetEstimationData
          ? state.initRepartitionData
          : state.repartitionData
            ? updateInstallmentRepartitionAmountsData({
                previousRepartitionData: state.repartitionData,
                calculatedContribution: action.calculatedContribution,
                installmentAmountValidationParams: action.installmentAmountValidationParams
              })
            : null,
        calculatedContribution: action.shouldResetEstimationData
          ? initialState.calculatedContribution
          : state.calculatedContribution,
        // if we get calculatedContribution we can reset calculatedContributionError
        // else we keep calculatedContributionError state as it is
        calculatedContributionError: action.calculatedContribution
          ? initialState.calculatedContributionError
          : state.calculatedContributionError
      };
    case InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_FAILURE:
      if (!action.params) {
        return {
          ...state,
          calculatedContributionError: action.error,
          repartitionAmountModalVisibleState: false
        };
      }

      return {
        ...state,
        calculatedContributionError: action.error,
        repartitionAmountModalVisibleState: false,
        repartitionData: state.repartitionData
          ? updateInstallmentRepartitionAmountsData({
              previousRepartitionData: state.repartitionData,
              installmentAmountValidationParams: action.params
            })
          : null
      };
    case InstallmentActionsType.SET_INSTALLMENT_PAYMENT_MODE_CHOICE:
      return {
        ...state,
        paymentMode: action.paymentMode
      };
    case InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_REQUEST:
      return {
        ...state,
        calculatedContribution: initialState.calculatedContribution,
        calculatedContributionError: initialState.calculatedContributionError
      };
    case InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_SUCCESS:
      return {
        ...state,
        calculatedContribution: action.contributionData
      };
    case InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_FAILURE:
      return {
        ...state,
        calculatedContributionError: action.error
      };
    case InstallmentActionsType.INIT_CALCULATED_CONTRIBUTION_DATA:
      return {
        ...state,
        calculatedContribution: initialState.calculatedContribution,
        calculatedContributionError: initialState.calculatedContributionError
      };
    case InstallmentActionsType.CALCULATED_CONTRIBUTION_INIT_ERROR:
      return {
        ...state,
        calculatedContributionError: initialState.calculatedContributionError
      };
    case InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        paymentModeData: action.paymentModeData
      };
    case InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        paymentResponseData: action.paymentResponseData
      };
    case InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_FAILURE:
    case InstallmentActionsType.ON_VVP_EDIT_FAILURE:
    case InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_FAILURE:
    case InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_FAILURE:
    case InstallmentActionsType.ON_VVP_DELETE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case InstallmentActionsType.ON_VVP_EDIT_SUCCESS:
      return buildEditVVPData({
        state: initialState,
        editedVVPData: action.editedVVPData,
        revievedRepartition: action.revievedRepartition
      });
    case InstallmentActionsType.CLEAR_INSTALLMENT_DATA:
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return initialState;
    case InstallmentActionsType.INSTALLMENT_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        error: initialState.error,
        calculatedContributionError: initialState.calculatedContributionError
      };
    default:
      return state;
  }
}
