import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  OnboardingStepper,
  SimpleButton,
  useScreenSizes,
  useTranslation
} from '@components/index';

import { RouteNames } from '@constants/navigation';
import { constructStepperItems } from '@pages/Common/utils';

import { ProfitSharingIncentiveHeader } from '../components';
import { ProfitSharingIncentiveOnboardingPageProps } from './interfaces';
import { useStyles } from './styles';
import { locator } from '@constants/locator';
import { label } from './label';
import {RouteProp, useNavigation} from '@react-navigation/native';
import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum,
} from '@constants/ProfitSharingIncentive';
import {useRoute} from "@react-navigation/core";
import {
  ProfitSharingIncentiveNavigatorInterface,
  ProfitSharingIncentiveNavigatorRouteNames
} from "@navigation/Interfaces";

export const ProfitSharingIncentiveOnboardingPageComponent: React.FC<ProfitSharingIncentiveOnboardingPageProps> = ({
  profitSharingIncentiveType = ProfitSharingIncentiveTypeEnum.incentive,
  profitSharingIncentiveLegalFramework = ProfitSharingIncentiveLegalFrameworkEnum.normal
}) => {
  const navigation = useNavigation();
  const { formatMessage, getMessageRaw } = useTranslation();
  const { isDesktop } = useScreenSizes();
  const style = useStyles();
  const route = useRoute<
      RouteProp<
          ProfitSharingIncentiveNavigatorInterface,
          ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveOnboarding
      >
  >();
  const { transactionId } = route.params || {};

  const stepperItems = constructStepperItems({
    size: 3,
    formatMessage,
    messageIds:
      label.steps[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
      label.steps[profitSharingIncentiveType]?.default
  });

  const handleSubmitPress = useCallback(() => {
    navigation.navigate(RouteNames.ProfitSharingIncentive, {
      screen: RouteNames.ProfitSharingIncentiveAssigment,
      params: { transactionId }
    });
  }, [navigation, transactionId]);

  const renderHeader = useCallback(() => {
    return (
      <ProfitSharingIncentiveHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: label.header.help.content })}
        helpPointModalTitle={formatMessage({ id: label.header.help.title })}
        helpPointPosition="left"
        title={formatMessage({
          id:
            label.header.title[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
            label.header.title[profitSharingIncentiveType]?.default
        })}
      />
    );
  }, [formatMessage]);

  const renderSubmitButton = useCallback(() => {
    return (
      <View style={style.buttonContainer}>
        <SimpleButton
          onPress={handleSubmitPress}
          title={formatMessage({ id: label.button.next })}
          testId={locator._pi._start}
        />
      </View>
    );
  }, [formatMessage, handleSubmitPress, style.buttonContainer]);

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={style.dashboardWrapper}
      renderStickyMobileBottom={renderSubmitButton}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={() => renderHeader()}
      >
        <OnboardingStepper
          stepperItems={stepperItems}
          title={formatMessage({ id: label.title })}
        />
        {isDesktop && renderSubmitButton()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
