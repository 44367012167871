import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { DashboardState, OCDState } from '@modules/dashboard/types';
import {
  distributeAllocations,
  handleContributionByCompany,
  setAllPlans,
  filterExternalPlans
} from '@modules/dashboard/utils';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';

import {
  AccountsAction,
  DashboardActionsType,
  GetToolSuccessAction
} from './actions/dashboardActionsTypes';

export const initialState: DashboardState = {
  userAccounts: undefined,
  availabilities: undefined,
  selectedCompany: undefined,
  allocations: undefined,
  allPlans: [],
  savingsPlans: [
    {
      planId: '',
      title: '',
      totalAmount: 0,
      totalAddedValue: 0,
      planFamily: 'ES'
    }
  ],
  OCDTop: [],
  OCDBottom: [],
  recentOperations: [],
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  availabilitiesError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  allocationsError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  OCDError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  recentOperationsError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  tools: {},
  toolsError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  contributionError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  contributionByCompany: null,
  contributionByOperation: null,
  contributionCibledContent: null,
  contributionCibledError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  budgetInsightUrl: '',
  budgetInsightManagerUrl: '',
  budgetInsightAccounts: [],
  budgetInsightError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  budgetInsightGetUrlError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  ssoUrl: '',
  ssoUrlError: undefined
};

const manageToolsByFlow = (action: GetToolSuccessAction, state: DashboardState) => {
  return {
    ...state.tools,
    [action.flow]: action.tools
  };
};

export default function DashboardReducer(
  state: DashboardState = initialState,
  action: AccountsAction | AuthenticateAction
): DashboardState {
  switch (action?.type) {
    case DashboardActionsType.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        userAccounts: action.accounts
      };
    case DashboardActionsType.PUT_SELECTED_COMPANY_SUCCESS:
      return {
        ...state,
        ...{
          selectedCompany: action.company,
          availabilities: undefined,
          allocations: undefined,
          OCDTop: [],
          OCDBottom: []
        }
      };
    case DashboardActionsType.GET_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        ...{
          availabilities: action.availabilities,
          availabilitiesError: initialState.availabilitiesError
        }
      };
    case DashboardActionsType.GET_ALLOCATIONS_SUCCESS:
      const fitredExternalPlans = filterExternalPlans(action.allocations);
      return {
        ...state,
        ...{
          allocations: fitredExternalPlans,
          allPlans: setAllPlans(fitredExternalPlans),
          savingsPlans: distributeAllocations(fitredExternalPlans, state),
          allocationsError: initialState.allocationsError
        }
      };
    case DashboardActionsType.GET_RECENT_OPERATIONS_SUCCESS:
      return {
        ...state,
        recentOperations: action.recentOperations,
        recentOperationsError: initialState.recentOperationsError
      };
    case DashboardActionsType.GET_TOOL_SUCCESS:
      return {
        ...state,
        tools: manageToolsByFlow(action, state),
        toolsError: initialState.toolsError
      };
    case DashboardActionsType.GET_OCD_SUCCESS:
      const OCDTop: OCDState[] = [];
      const OCDBottom: OCDState[] = [];
      action.OCD.forEach((item: OCDState) => {
        if (item.position === 1) {
          OCDTop.push(item);
        } else if (item.position === 2) {
          OCDBottom.push(item);
        }
      });
      return {
        ...state,
        OCDTop: OCDTop,
        OCDBottom: OCDBottom,
        OCDError: initialState.OCDError
      };
    case DashboardActionsType.GET_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        contributionByCompany: handleContributionByCompany(action.contribution)
      };
    case DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_SUCCESS:
      return {
        ...state,
        contributionByOperation: action.contributionByOperation
      };
    case DashboardActionsType.GET_CONTRIBUTION_FAILURE:
      return {
        ...state,
        contributionError: action.error
      };
    case DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        contributionCibledContent: action.content
      };
    case DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_FAILURE:
      return {
        ...state,
        contributionCibledError: action.error
      };
    case DashboardActionsType.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case DashboardActionsType.GET_ALLOCATIONS_FAILURE:
      return {
        ...state,
        ...{ allocationsError: action.error }
      };
    case DashboardActionsType.GET_AVAILABILITIES_FAILURE:
      return {
        ...state,
        ...{
          availabilitiesError: action.error
        }
      };
    case DashboardActionsType.GET_TOOL_FAILURE:
      return {
        ...state,
        error: action.error,
        toolsError: action.error
      };
    case DashboardActionsType.GET_OCD_FAILURE:
      return {
        ...state,
        error: action.error,
        OCDError: action.error
      };
    case DashboardActionsType.GET_RECENT_OPERATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        recentOperationsError: action.error
      };
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return initialState;
    case DashboardActionsType.GET_BUDGET_INSIGHT_URL_SUCCESS:
      return {
        ...state,
        budgetInsightUrl: action.budgetInsightUrl
      };
    case DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        budgetInsightAccounts: action.budgetInsightAccounts
      };

    case DashboardActionsType.GET_BUDGET_INSIGHT_URL_FAILURE:
      return {
        ...state,
        budgetInsightGetUrlError: action.error
      };
    case DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_FAILURE:
      return {
        ...state,
        budgetInsightError: action.error
      };
    case DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_SUCCESS:
      return {
        ...state,
        budgetInsightManagerUrl: action.budgetInsightManagerUrl
      };

    case DashboardActionsType.DASHBOARD_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        ...{
          error: initialState.error,
          availabilitiesError: initialState.availabilitiesError,
          allocationsError: initialState.allocationsError,
          OCDError: initialState.OCDError,
          recentOperationsError: initialState.recentOperationsError,
          contributionError: initialState.contributionError,
          budgetInsightError: initialState.budgetInsightError,
          budgetInsightGetUrlError: initialState.budgetInsightError
        }
      };
    case DashboardActionsType.AVAILABILITIES_INIT_ERROR:
      return {
        ...state,
        availabilitiesError: initialState.availabilitiesError
      };
    case DashboardActionsType.ALLOCATIONS_INIT_ERROR:
      return {
        ...state,
        allocationsError: initialState.allocationsError
      };
    case DashboardActionsType.OCD_INIT_ERROR:
      return {
        ...state,
        OCDError: initialState.OCDError
      };
    case DashboardActionsType.RECENT_OPERATIONS_INIT_ERROR:
      return {
        ...state,
        recentOperationsError: initialState.recentOperationsError
      };
    case DashboardActionsType.GET_SSO_REDIRECT_URL_SUCCESS:
      return {
        ...state,
        ssoUrl: action.data,
        ssoUrlError: initialState.ssoUrlError
      };
    case DashboardActionsType.GET_SSO_REDIRECT_URL_FAILURE:
      return {
        ...state,
        ssoUrlError: action.error,
        ssoUrl: initialState.ssoUrl
      };

    default:
      return state;
  }
}
