import { PlanFamillyList } from '@constants/index';
import { ApiPaymentPeriodicityEnum, PaymentModeList } from '@constants/installment';
import { OperationDecisionState } from '@modules/common/types';
import {
  InstallmentAmountValidationParamsType,
  InstallmentCalculatedContributionState,
  InstallmentRepartitionState,
  InstallmentState
} from '@modules/installment/types';
import { OnGoingVVP } from '@modules/savings/types';

const installmentMock = (i: number) => ({
  code: '3456432°' + i,
  totalAmount: 2000 + i,
  label: 'Installment_' + i
});

const installmentsMock = Array.from(Array(5), (_, i) => installmentMock(i));

export const mockInstallments = Array.from(Array(5), (_, i) => ({
  ...installmentMock(i),
  items: installmentsMock
}));

export const mockInstallmentAmountValidationParamsWithContribution: InstallmentAmountValidationParamsType =
  {
    amount: 100,
    contribution: {
      companyId: 'companyId',
      planId: 'planId',
      contributionTotalAmount: 1000,
      distributions: []
    },
    managementId: '1234',
    isContributionActive: false,
    fundId: '1234'
  };

export const mockInstallmentIneligibleState: OperationDecisionState = {
  elligibleDispositifs: [],
  dispositifs: [
    {
      id: '2001',
      name: 'PEE',
      encours: 14635.23,
      filterProperties: {
        VVL: true,
        VVP: true,
        TaxOut: false,
        TaxIn: true,
        RetirementOnly: false,
        Abondement: true,
        Invested: true,
        ThresholdTrigger: false,
        StockReallocation: true,
        PartialReallocation: true,
        FlowReallocation: false,
        OadReallocation: true,
        Contribution: true
      },
      hasOnGoingRcVvp: false,
      planFamily: 'ES',
      advancedProperties: {
        HasIncorrectDrivingData: true,
        ContributionConsumed: 150,
        ContributionLimit: 4000
      },
      blocagesCodes: []
    },
    {
      id: '2002',
      name: 'PERECO',
      encours: 24635.23,
      filterProperties: {
        VVL: true,
        VVP: true,
        TaxOut: true,
        TaxIn: true,
        RetirementOnly: true,
        Abondement: true,
        Invested: true,
        ThresholdTrigger: false,
        StockReallocation: true,
        PartialReallocation: true,
        FlowReallocation: false,
        OadReallocation: false,
        Contribution: true,
        HideInformationVvInf25: true
      },
      hasOnGoingRcVvp: false,
      planFamily: 'ES',
      advancedProperties: {
        HasIncorrectDrivingData: true,
        ContributionConsumed: 25,
        ContributionLimit: 5100
      },
      blocagesCodes: []
    },
    {
      id: '2501',
      name: 'PERO',
      encours: 16130,
      filterProperties: {
        VVL: true,
        VVP: true,
        TaxOut: true,
        TaxIn: true,
        RetirementOnly: true,
        Abondement: false,
        Invested: true,
        ThresholdTrigger: false,
        StockReallocation: true,
        PartialReallocation: false,
        FlowReallocation: true,
        OadReallocation: false,
        Contribution: false
      },
      hasOnGoingRcVvp: true,
      planFamily: 'RC',
      advancedProperties: {
        HasIncorrectDrivingData: false,
        ContributionConsumed: 25,
        ContributionLimit: 5100
      },
      blocagesCodes: []
    }
  ],
  questions: []
};

export const mockInstallmentRepartition: InstallmentRepartitionState = {
  planId: 2501,
  planName: 'PERO',
  planType: 'PERO',
  compartments: [
    {
      code: '1',
      label: 'Versements volontaires déductibles',
      managements: [
        {
          id: '10001',
          code: 'Gestion à Horizon',
          label: 'Gestion à Horizon',
          totalAmount: 3330.0,
          supports: [
            {
              supportIsin: 'FR0013391455',
              supportName: 'Fonds FR0013391455',
              supportType: null,
              hasNoFundSheet: false,
              source: 'UNKNOWN',
              supportIdNoee: null,
              riskLevelSRRI: null,
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 1500.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: null,
                currency: null,
                dateValue: null
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'QS0002105TY1',
              supportName: 'MULTIPAR MONETAIRE SELECTION',
              supportType: null,
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '1039',
              riskLevelSRRI: '1',
              riskLevelSRI: '1',
              performanceValue: 0.392431165575058,
              amount: {
                amount: 1000.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'FCP',
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 11.0915,
                currency: 'EUR',
                dateValue: '2020-09-17T00:00:00+02:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2018-02-11T00:00:00+01:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Monétaire'
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0010839274',
              supportName: 'BNP Paribas Perspectives 2028-2030',
              supportType: null,
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '25039',
              riskLevelSRRI: '4',
              riskLevelSRI: '3',
              performanceValue: 1.53847295887877,
              amount: {
                amount: 500.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'SICAV',
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 29.5929,
                currency: 'EUR',
                dateValue: '2020-09-17T00:00:00+02:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2018-02-11T00:00:00+01:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Non applicable'
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FG CS',
              supportName: 'CARDIF Sécurité',
              supportType: null,
              hasNoFundSheet: false,
              source: 'UNKNOWN',
              supportIdNoee: null,
              riskLevelSRRI: null,
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 330.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: null,
                currency: null,
                dateValue: null
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Action'
              },
              filterProperties: {},
              isISR: false
            }
          ],
          order: 0,
          isFree: false,
          filterProperties: {
            TaxOut: true,
            TestPERO_Comp1: false,
            VVL: true,
            VVP: true
          },
          advancedProperties: {
            HasIncorrectDrivingData: true,
            TotalAddedValue: 0.0,
            HasContribution: false
          },
          isFictive: false
        }
      ],
      order: 3,
      isFictive: false
    }
  ],
  filterProperties: {
    VVL: true,
    VVP: true,
    TaxOut: true,
    TaxIn: true,
    RetirementOnly: true,
    Abondement: false,
    Invested: true,
    ThresholdTrigger: false,
    StockReallocation: true,
    PartialReallocation: false,
    FlowReallocation: true,
    OadReallocation: false,
    Contribution: false,
    TestPERO: true
  },
  advancedProperties: {
    Contracts: null,
    Conventions: null,
    HasOnGoingRcVvp: true,
    VVLLimit: 100.0,
    VVPMonthlyLimit: 30.0,
    VVPQuaterlyLimit: 60.0,
    VVPSemiAnnualLimit: 120.0,
    VVPAnnualLimit: 240.0,
    HasContribution: false
  },
  colors: ['#F28B2D'],
  planFamily: PlanFamillyList.RC,
  label: '​PER Obligatoire',
  shortLabel: 'PERO',
  horizon: 'Retraite'
};

export const mockInstallmentState: InstallmentState = {
  decision: {
    elligibleDispositifs: [],
    dispositifs: [
      {
        id: '2001',
        name: 'PEE',
        encours: 14635.23,
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true
        },
        hasOnGoingRcVvp: false,
        planFamily: 'ES',
        advancedProperties: {
          ContributionConsumed: 150,
          ContributionLimit: 4000
        },
        blocagesCodes: []
      },
      {
        id: '2002',
        name: 'PERECO',
        encours: 24635.23,
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true,
          HideInformationVvInf25: true
        },
        hasOnGoingRcVvp: false,
        planFamily: 'ES',
        advancedProperties: {
          ContributionConsumed: 25,
          ContributionLimit: 5100
        },
        blocagesCodes: []
      }
    ],
    questions: [
      {
        respondedTitle: '',
        key: 'VV_needs_VersementType',
        title: 'Je définis mon versement',
        text: 'VV_needs_VersementType',
        tooltip: 'Tooltip FR',
        showEligibility: false,
        responses: [
          {
            title: 'Ponctuel',
            text: "<p>J​'effectue un versement aujourd'hui<br></p>",
            propertyFilter: {
              name: 'VVL',
              value: true
            },
            options: [],
            type: ''
          },
          {
            title: 'Programmé',
            text: '<p>​Je programme un versement régulier&#58;\r\n<br><br>\r\nVotre opération sera prise en compte conformément au calendrier prévu par votre entreprise.</p>',
            propertyFilter: {
              name: 'VVP',
              value: true
            },
            options: [
              {
                name: 'VVP',
                value: '1M',
                labelCode: 'VV_option_1M'
              },
              {
                name: 'VVP',
                value: '3M',
                labelCode: 'VV_option_3M'
              },
              {
                name: 'VVP',
                value: '6M',
                labelCode: 'VV_option_6M'
              }
            ],
            type: ''
          }
        ],
        isRequired: true
      },
      {
        respondedTitle: '',
        key: 'VV_needs_FiscalDeduction',
        title: "Je souhaite bénéficier d'un avantage fiscal",
        text: 'VV_needs_FiscalDeduction',
        tooltip: 'VV_needs_FiscalDeduction',
        showEligibility: true,
        responses: [
          {
            title: 'Maintenant',
            text: 'Je déduis le versement de mon revenu impossable.</br>\r\nJe note que ce montant sera soumis à l\'impot sur le revenu&#58; lors de mon départ à la retraite ou si je l\'utilise, par anticipation, pour acheter ma résidence principale.</br>\r\n<a href="http&#58;//www.google.com">En savoir plus sur la fiscalité</a></br>\r\n<a href="http&#58;//www.google.com">Simuler ma fiscalité</a>',
            propertyFilter: {
              name: 'TaxOut',
              value: true
            },
            options: [],
            type: ''
          },
          {
            title: 'Plus tard',
            text: 'Je ne souhaite pas déduire le versement de mon revenu imposable cette année.</br>\r\nDans ce cas, lorsque les sommes versées me seront restituées sous forme de capital (ex&#58; en cas de déblocage anticipé ou dès que l\'épargne est disponible). Je ne paierai pas un supplément d\'impôt sur le revenu.</br>\r\n<a href="http&#58;//www.google.com">En savoir plus sur la fiscalité</a></br>\r\n<a href="http&#58;//www.google.com">Simuler ma fiscalité</a>',
            propertyFilter: {
              name: 'TaxIn',
              value: true
            },
            options: [],
            type: ''
          }
        ],
        isRequired: true
      },
      {
        respondedTitle: '',
        key: 'VV_needs_OptimisationAbondement',
        title: "Optimiser mon abondement c'est",
        text: 'VV_needs_OptimisationAbondement',
        tooltip: 'VV_needs_OptimisationAbondement',
        showEligibility: true,
        responses: [
          {
            title: 'Essentiel',
            text: 'Je veux investir uniquement sur les supports de placements abondés',
            propertyFilter: {
              name: 'Abondement',
              value: true
            },
            options: [],
            type: ''
          },
          {
            title: 'Non essentiel',
            text: "Je veux être libre d'investir sur des supports de placement qu'ils soient abondés ou non",
            options: [],
            type: ''
          }
        ],
        isRequired: false
      },
      {
        respondedTitle: '',
        key: 'VV_needs_JeChoisisMonBesoin',
        title: 'Je choisis mon besoin',
        text: 'VV_needs_JeChoisisMonBesoin',
        tooltip: 'VV_needs_JeChoisisMonBesoin',
        showEligibility: true,
        responses: [
          {
            title: "Je n'ai pas de besoin particulier",
            text: 'Je répartis mon versement sur des dispositifs à moyen ou long terme',
            options: [],
            type: ''
          },
          {
            title: 'Epargner pour un projet',
            text: 'Mon épargne sera disponible dans 5 ans (sauf en cas de déblocage anticipé)',
            propertyFilter: {
              name: 'RetirementOnly',
              value: false
            },
            options: [],
            type: ''
          },
          {
            title: 'Epargner pour ma retraite',
            text: 'Mon épargne sera disponible lors de mon départ à la retraite (sauf en cas de déblocage anticipé)',
            propertyFilter: {
              name: 'RetirementOnly',
              value: true
            },
            options: [],
            type: ''
          }
        ],
        isRequired: false
      },
      {
        respondedTitle: '',
        key: 'VV_needs_JeChoisisCommentInvestir',
        title: 'Je choisis comment investir',
        text: 'VV_needs_JeChoisisCommentInvestir',
        tooltip: 'VV_needs_JeChoisisCommentInvestir',
        showEligibility: false,
        responses: [
          {
            title: 'Investir comme je veux',
            text: "Je veux investir sur tous mes supports de placement qui m'intéressent",
            options: [],
            type: ''
          },
          {
            title: "Investir comme d'habitude",
            text: "Je veux investir uniquement sur mes supports de placements sur lesquels j'ai déjà investi",
            propertyFilter: {
              name: 'Invested',
              value: true
            },
            options: [
              {
                name: 'SupportFilter',
                value: 'INVESTED',
                labelCode: 'INVESTED'
              }
            ],
            type: ''
          },
          {
            title: 'Donner plus de sens à mon épargne',
            text: "Je veux investir uniquement sur mes supports à impact positif sur la société et/ou l'environnement",
            options: [
              {
                name: 'SupportFilter',
                value: 'RESPONSIBLE',
                labelCode: 'RESPONSIBLE'
              }
            ],
            type: 'VVCommeJeVeux'
          }
        ],
        isRequired: false
      }
    ]
  },
  repartitionData: {
    planId: 2501,
    planName: 'PERO',
    planType: 'PERO',
    compartments: [
      {
        code: '1',
        label: 'Versements volontaires déductibles',
        managements: [
          {
            id: '10001',
            code: 'Gestion à Horizon',
            label: 'Gestion à Horizon',
            totalAmount: 3330.0,
            supports: [
              {
                supportIsin: 'FR0013391455',
                supportName: 'Fonds FR0013391455',
                supportType: null,
                hasNoFundSheet: false,
                source: 'UNKNOWN',
                supportIdNoee: null,
                riskLevelSRRI: null,
                riskLevelSRI: null,
                performanceValue: null,
                amount: {
                  amount: 1500.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: null,
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: null,
                  currency: null,
                  dateValue: null
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: null,
                advancedProperties: {
                  HasThresholdTrigger: false
                },
                filterProperties: {},
                isISR: false
              },
              {
                supportIsin: 'QS0002105TY1',
                supportName: 'MULTIPAR MONETAIRE SELECTION',
                supportType: null,
                hasNoFundSheet: false,
                source: 'BNPP',
                supportIdNoee: '1039',
                riskLevelSRRI: '1',
                riskLevelSRI: '1',
                performanceValue: 0.392431165575058,
                amount: {
                  amount: 1000.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: 'FCP',
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: 11.0915,
                  currency: 'EUR',
                  dateValue: '2020-09-17T00:00:00+02:00'
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: '2018-02-11T00:00:00+01:00',
                advancedProperties: {
                  HasThresholdTrigger: false,
                  CategorieClassification: 'Monétaire'
                },
                filterProperties: {},
                isISR: false
              },
              {
                supportIsin: 'FR0010839274',
                supportName: 'BNP Paribas Perspectives 2028-2030',
                supportType: null,
                hasNoFundSheet: false,
                source: 'BNPP',
                supportIdNoee: '25039',
                riskLevelSRRI: '4',
                riskLevelSRI: '3',
                performanceValue: 1.53847295887877,
                amount: {
                  amount: 500.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: 'SICAV',
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: 29.5929,
                  currency: 'EUR',
                  dateValue: '2020-09-17T00:00:00+02:00'
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: '2018-02-11T00:00:00+01:00',
                advancedProperties: {
                  HasThresholdTrigger: false,
                  CategorieClassification: 'Non applicable'
                },
                filterProperties: {},
                isISR: false
              },
              {
                supportIsin: 'FG CS',
                supportName: 'CARDIF Sécurité',
                supportType: null,
                hasNoFundSheet: false,
                source: 'UNKNOWN',
                supportIdNoee: null,
                riskLevelSRRI: null,
                riskLevelSRI: null,
                performanceValue: null,
                amount: {
                  amount: 330.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: null,
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: null,
                  currency: null,
                  dateValue: null
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: null,
                advancedProperties: {
                  HasThresholdTrigger: false,
                  CategorieClassification: 'Action'
                },
                filterProperties: {},
                isISR: false
              }
            ],
            order: 0,
            isFree: false,
            filterProperties: {
              TaxOut: true,
              TestPERO_Comp1: false,
              VVL: true,
              VVP: true
            },
            advancedProperties: {
              TotalAddedValue: 0.0,
              HasContribution: false
            },
            isFictive: false
          }
        ],
        order: 3,
        isFictive: false
      }
    ],
    filterProperties: {
      VVL: true,
      VVP: true,
      TaxOut: true,
      TaxIn: true,
      RetirementOnly: true,
      Abondement: false,
      Invested: true,
      ThresholdTrigger: false,
      StockReallocation: true,
      PartialReallocation: false,
      FlowReallocation: true,
      OadReallocation: false,
      Contribution: false,
      TestPERO: true
    },
    advancedProperties: {
      Contracts: null,
      Conventions: null,
      HasOnGoingRcVvp: true,
      VVLLimit: 100.0,
      VVPMonthlyLimit: 30.0,
      VVPQuaterlyLimit: 60.0,
      VVPSemiAnnualLimit: 120.0,
      VVPAnnualLimit: 240.0,
      HasContribution: false
    },
    colors: ['#F28B2D'],
    planFamily: PlanFamillyList.RC,
    label: '​PER Obligatoire',
    shortLabel: 'PERO',
    horizon: 'Retraite'
  },
  initRepartitionData: {
    planId: 2501,
    planName: 'PERO',
    planType: 'PERO',
    compartments: [
      {
        code: '1',
        label: 'Versements volontaires déductibles',
        managements: [
          {
            id: '10001',
            code: 'Gestion à Horizon',
            label: 'Gestion à Horizon',
            totalAmount: 3330.0,
            supports: [
              {
                supportIsin: 'FR0013391455',
                supportName: 'Fonds FR0013391455',
                supportType: null,
                hasNoFundSheet: false,
                source: 'UNKNOWN',
                supportIdNoee: null,
                riskLevelSRRI: null,
                riskLevelSRI: null,
                performanceValue: null,
                amount: {
                  amount: 1500.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: null,
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: null,
                  currency: null,
                  dateValue: null
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: null,
                advancedProperties: {
                  HasThresholdTrigger: false
                },
                filterProperties: {},
                isISR: false
              },
              {
                supportIsin: 'QS0002105TY1',
                supportName: 'MULTIPAR MONETAIRE SELECTION',
                supportType: null,
                hasNoFundSheet: false,
                source: 'BNPP',
                supportIdNoee: '1039',
                riskLevelSRRI: '1',
                riskLevelSRI: '1',
                performanceValue: 0.392431165575058,
                amount: {
                  amount: 1000.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: 'FCP',
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: 11.0915,
                  currency: 'EUR',
                  dateValue: '2020-09-17T00:00:00+02:00'
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: '2018-02-11T00:00:00+01:00',
                advancedProperties: {
                  HasThresholdTrigger: false,
                  CategorieClassification: 'Monétaire'
                },
                filterProperties: {},
                isISR: false
              },
              {
                supportIsin: 'FR0010839274',
                supportName: 'BNP Paribas Perspectives 2028-2030',
                supportType: null,
                hasNoFundSheet: false,
                source: 'BNPP',
                supportIdNoee: '25039',
                riskLevelSRRI: '4',
                riskLevelSRI: '3',
                performanceValue: 1.53847295887877,
                amount: {
                  amount: 500.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: 'SICAV',
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: 29.5929,
                  currency: 'EUR',
                  dateValue: '2020-09-17T00:00:00+02:00'
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: '2018-02-11T00:00:00+01:00',
                advancedProperties: {
                  HasThresholdTrigger: false,
                  CategorieClassification: 'Non applicable'
                },
                filterProperties: {},
                isISR: false
              },
              {
                supportIsin: 'FG CS',
                supportName: 'CARDIF Sécurité',
                supportType: null,
                hasNoFundSheet: false,
                source: 'UNKNOWN',
                supportIdNoee: null,
                riskLevelSRRI: null,
                riskLevelSRI: null,
                performanceValue: null,
                amount: {
                  amount: 330.0,
                  addedValue: 0.0,
                  numberOfUnits: 0.0
                },
                rate: null,
                supportDetailsUrl: null,
                legalStatus: null,
                isMaster: false,
                hasContribution: false,
                netAssetValue: {
                  amount: null,
                  currency: null,
                  dateValue: null
                },
                plans: null,
                hasVersement: true,
                hasInteressement: false,
                hasParticipation: false,
                nextNavDate: null,
                advancedProperties: {
                  HasThresholdTrigger: false,
                  CategorieClassification: 'Action'
                },
                filterProperties: {},
                isISR: false
              }
            ],
            order: 0,
            isFree: false,
            filterProperties: {
              TaxOut: true,
              TestPERO_Comp1: false,
              VVL: true,
              VVP: true
            },
            advancedProperties: {
              TotalAddedValue: 0.0,
              HasContribution: false
            },
            isFictive: false
          }
        ],
        order: 3,
        isFictive: false
      }
    ],
    filterProperties: {
      VVL: true,
      VVP: true,
      TaxOut: true,
      TaxIn: true,
      RetirementOnly: true,
      Abondement: false,
      Invested: true,
      ThresholdTrigger: false,
      StockReallocation: true,
      PartialReallocation: false,
      FlowReallocation: true,
      OadReallocation: false,
      Contribution: false,
      TestPERO: true
    },
    advancedProperties: {
      Contracts: null,
      Conventions: null,
      HasOnGoingRcVvp: true,
      VVLLimit: 100.0,
      VVPMonthlyLimit: 30.0,
      VVPQuaterlyLimit: 60.0,
      VVPSemiAnnualLimit: 120.0,
      VVPAnnualLimit: 240.0,
      HasContribution: false
    },
    colors: ['#F28B2D'],
    planFamily: PlanFamillyList.RC,
    label: '​PER Obligatoire',
    shortLabel: 'PERO',
    horizon: 'Retraite'
  },
  repartitionAmountModalVisibleState: false,
  calculatedContribution: null,
  calculatedContributionError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  paymentResponseData: null,
  paymentMode: PaymentModeList.cb,
  paymentModeData: [],
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
};
export const mockInstallmentAmountValidationParams: InstallmentAmountValidationParamsType = {
  amount: 100,
  contribution: null,
  managementId: '1234',
  isContributionActive: false,
  fundId: '1234'
};

export const mockRepartitionData: InstallmentRepartitionState = {
  planId: 2501,
  planName: 'PEE',
  planType: 'PEE',
  compartments: [
    {
      code: '1',
      label: 'Versements volontaires déductibles',
      managements: [
        {
          id: '10001',
          code: 'Gestion à Horizonss',
          label: 'Gestion à Horizonsss',
          totalAmount: 3330.0,
          supports: [
            {
              supportIsin: 'FR0013391455',
              supportName: 'Fonds FR0013391455',
              supportType: null,
              hasNoFundSheet: false,
              source: 'UNKNOWN',
              supportIdNoee: null,
              riskLevelSRRI: null,
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 1500.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: null,
                currency: null,
                dateValue: null
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'QS0002105TY1',
              supportName: 'MULTIPAR MONETAIRE SELECTION',
              supportType: null,
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '1039',
              riskLevelSRRI: '1',
              riskLevelSRI: '1',
              performanceValue: 0.392431165575058,
              amount: {
                amount: 1000.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'FCP',
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 11.0915,
                currency: 'EUR',
                dateValue: '2020-09-17T00:00:00+02:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2018-02-11T00:00:00+01:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Monétaire'
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0010839274',
              supportName: 'BNP Paribas Perspectives 2028-2030',
              supportType: null,
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '25039',
              riskLevelSRRI: '4',
              riskLevelSRI: '3',
              performanceValue: 1.53847295887877,
              amount: {
                amount: 500.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'SICAV',
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 29.5929,
                currency: 'EUR',
                dateValue: '2020-09-17T00:00:00+02:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2018-02-11T00:00:00+01:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Non applicable'
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FG CS',
              supportName: 'CARDIF Sécurité',
              supportType: null,
              hasNoFundSheet: false,
              source: 'UNKNOWN',
              supportIdNoee: null,
              riskLevelSRRI: null,
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 330.0,
                addedValue: 0.0,
                numberOfUnits: 0.0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: null,
                currency: null,
                dateValue: null
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Action'
              },
              filterProperties: {},
              isISR: false
            }
          ],
          order: 0,
          isFree: false,
          filterProperties: {
            TaxOut: true,
            TestPERO_Comp1: false,
            VVL: true,
            VVP: true
          },
          advancedProperties: {
            TotalAddedValue: 0.0,
            HasContribution: false
          },
          isFictive: false
        }
      ],
      order: 3,
      isFictive: false
    }
  ],
  filterProperties: {
    VVL: true,
    VVP: true,
    TaxOut: true,
    TaxIn: true,
    RetirementOnly: true,
    Abondement: false,
    Invested: true,
    ThresholdTrigger: false,
    StockReallocation: true,
    PartialReallocation: false,
    FlowReallocation: true,
    OadReallocation: false,
    Contribution: false,
    TestPERO: true
  },
  advancedProperties: {
    Contracts: null,
    Conventions: null,
    HasOnGoingRcVvp: true,
    VVLLimit: 100.0,
    VVPMonthlyLimit: 30.0,
    VVPQuaterlyLimit: 60.0,
    VVPSemiAnnualLimit: 120.0,
    VVPAnnualLimit: 240.0,
    HasContribution: false
  },
  colors: ['#F28B2D'],
  planFamily: PlanFamillyList.ES,
  label: '​PER Obligatoire',
  shortLabel: 'PEE',
  horizon: 'Epargne'
};

export const mockRepartitionTargetedData: InstallmentRepartitionState = {
  planId: 4001,
  planName: 'PERECO',

  planType: 'PER C1',
  compartments: [
    {
      code: 'PER C1',
      label: 'Versements volontaires déductibles',
      managements: [
        {
          id: '0000434880',
          code: 'Piloté',
          label: 'Gestion Pilotée',
          totalAmount: 0,
          supports: [
            {
              supportIsin: 'FR0011351667',
              supportName: 'BNP PARIBAS PERSPECTIVES 2031-2033 I',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25041',
              riskLevelSRRI: '5',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 26.4224,
                currency: 'EUR',
                dateValue: '2022-07-13T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0013049442',
              supportName: 'BNP PARIBAS PERSPECTIVES 2034-2036 I',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25043',
              riskLevelSRRI: '5',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 22.8581,
                currency: 'EUR',
                dateValue: '2022-07-13T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0000174617',
              supportName: 'BNP PARIBAS PERSPECTIVES LONG TERME I ATTENTE',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25018',
              riskLevelSRRI: '5',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 48.829,
                currency: 'EUR',
                dateValue: '2022-07-13T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0010397307',
              supportName: 'BNP PARIBAS PERSPECTIVES 2025-2027 I',
              supportType: 'FONDS',
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '25037',
              riskLevelSRRI: '4',
              riskLevelSRI: '2',
              performanceValue: 0.6900193,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'SICAV',
              isMaster: true,
              hasContribution: true,
              netAssetValue: {
                amount: 25.1718,
                currency: 'EUR',
                dateValue: '2024-03-08T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2023-04-12T00:00:00+00:00',
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0000174591',
              supportName: 'BNP PARIBAS PERSPECTIVES 2022-2024 I',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25011',
              riskLevelSRRI: '3',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 35.5155,
                currency: 'EUR',
                dateValue: '2022-07-13T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0013391455',
              supportName: 'BNP PARIBAS PERSPECTIVES 2037-2039 I',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25047',
              riskLevelSRRI: '5',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 18.9776,
                currency: 'EUR',
                dateValue: '2022-07-13T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0000174567',
              supportName: 'BNP PARIBAS PERSPECTIVES COURT TERME I',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25017',
              riskLevelSRRI: '3',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 28.0385,
                currency: 'EUR',
                dateValue: '2022-07-13T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0010841593',
              supportName: 'BNP PARIBAS PERSPECTIVES 2028-2030 I',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25040',
              riskLevelSRRI: '4',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 30.1118,
                currency: 'EUR',
                dateValue: '2022-07-13T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR001400C2U5',
              supportName: 'BNP PARIBAS PERSPECTIVES 2040-2042 I',
              supportType: null,
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '25049',
              riskLevelSRRI: '4',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: false,
              netAssetValue: {
                amount: 20.4144,
                currency: 'EUR',
                dateValue: '2023-05-26T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            }
          ],
          order: 2,
          isFree: false,
          filterProperties: {
            Asset: true,
            Pension: true,
            TaxOut: true,
            VVL: true,
            VVP: true
          },
          advancedProperties: {
            HasIncorrectDrivingData: false,
            TotalAddedValue: 0,
            HasContribution: true
          },
          isFictive: false
        },
        {
          id: '0000434879',
          code: 'Libre',
          label: 'Gestion Libre',
          totalAmount: 0,
          supports: [
            {
              supportIsin: 'FR0010146878',
              supportName: 'BNP PARIBAS PERSPECTIVES MOYEN TERME I',
              supportType: 'FONDS',
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '25034',
              riskLevelSRRI: '4',
              riskLevelSRI: '3',
              performanceValue: 1.8567474,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'SICAV',
              isMaster: false,
              hasContribution: true,
              netAssetValue: {
                amount: 35.9866,
                currency: 'EUR',
                dateValue: '2024-03-08T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2023-04-12T00:00:00+00:00',
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0010146522',
              supportName: 'BNP PARIBAS PERSPECTIVES LONG TERME I',
              supportType: 'FONDS',
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '25030',
              riskLevelSRRI: '5',
              riskLevelSRI: '4',
              performanceValue: 3.415525,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'SICAV',
              isMaster: false,
              hasContribution: true,
              netAssetValue: {
                amount: 39.4154,
                currency: 'EUR',
                dateValue: '2024-03-11T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2023-04-12T00:00:00+00:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Action'
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'FR0000174567',
              supportName: 'BNP PARIBAS PERSPECTIVES COURT TERME I',
              supportType: 'FONDS',
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '25017',
              riskLevelSRRI: '3',
              riskLevelSRI: '2',
              performanceValue: 0.8845007,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'SICAV',
              isMaster: false,
              hasContribution: true,
              netAssetValue: {
                amount: 29.2203,
                currency: 'EUR',
                dateValue: '2024-03-11T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2023-04-12T00:00:00+00:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Diversifié'
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'QS0002105T60',
              supportName: 'MULTIPAR SOLIDAIRE OBLIG S RP C',
              supportType: 'FONDS',
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '1034',
              riskLevelSRRI: '3',
              riskLevelSRI: '2',
              performanceValue: 0.0040507806,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'FCP (Fonds commun de placement) ',
              isMaster: false,
              hasContribution: true,
              netAssetValue: {
                amount: 12.3298,
                currency: 'EUR',
                dateValue: '2024-03-11T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2023-04-12T00:00:00+00:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Action'
              },
              filterProperties: {},
              isISR: true
            },
            {
              supportIsin: 'QS0002105T52',
              supportName: 'MULTIPAR SOLIDAIRE DYN S RSP C',
              supportType: 'FONDS',
              hasNoFundSheet: false,
              source: 'BNPP',
              supportIdNoee: '1029',
              riskLevelSRRI: '6',
              riskLevelSRI: '4',
              performanceValue: 4.8499794,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: 'FCP (Fonds commun de placement) ',
              isMaster: false,
              hasContribution: true,
              netAssetValue: {
                amount: 25.0452,
                currency: 'EUR',
                dateValue: '2024-03-08T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: '2023-04-12T00:00:00+00:00',
              advancedProperties: {
                HasThresholdTrigger: false,
                CategorieClassification: 'Action'
              },
              filterProperties: {},
              isISR: true
            },
            {
              supportIsin: 'QS000212QB90',
              supportName: 'MULTIPAR CROISSANCE INCLUSIVE - G',
              supportType: 'FONDS',
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '3399',
              riskLevelSRRI: '4',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: true,
              netAssetValue: {
                amount: 10.3987,
                currency: 'EUR',
                dateValue: '2023-05-30T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            },
            {
              supportIsin: 'QS000212P798',
              supportName: 'MULTIPAR AQUA - G',
              supportType: 'FONDS',
              hasNoFundSheet: true,
              source: '',
              supportIdNoee: '3403',
              riskLevelSRRI: '4',
              riskLevelSRI: null,
              performanceValue: null,
              amount: {
                amount: 0,
                addedValue: 0,
                numberOfUnits: 0
              },
              rate: null,
              supportDetailsUrl: null,
              legalStatus: null,
              isMaster: false,
              hasContribution: true,
              netAssetValue: {
                amount: 9.7081,
                currency: 'EUR',
                dateValue: '2023-05-26T00:00:00+00:00'
              },
              plans: null,
              hasVersement: true,
              hasInteressement: false,
              hasParticipation: false,
              nextNavDate: null,
              advancedProperties: {
                HasThresholdTrigger: false
              },
              filterProperties: {},
              isISR: false
            }
          ],
          order: 4,
          isFree: true,
          filterProperties: {
            Asset: true,
            Pension: true,
            TaxOut: true,
            VVL: true,
            VVP: true
          },
          advancedProperties: {
            HasIncorrectDrivingData: false,
            TotalAddedValue: 0,
            HasContribution: true
          },
          isFictive: false
        }
      ],
      order: 4,
      isFictive: false
    }
  ],
  filterProperties: {
    VVL: true,
    VVP: true,
    TaxOut: true,
    TaxIn: true,
    RetirementOnly: true,
    Abondement: true,
    Invested: false,
    ThresholdTrigger: true,
    StockReallocation: true,
    PartialReallocation: true,
    FlowReallocation: false,
    OadReallocation: true,
    ImmediateAsset: false,
    ImmediatePension: false,
    UnlockableAsset: false,
    UnlockablePension: false,
    Contribution: true,
    HideInformationVvInf25: true
  },
  advancedProperties: {
    Offres: '930650',
    ContributionConsumed: 362.5,
    ContributionLimit: 1512.5,
    ContributionPotential: 1150,
    HasOnGoingRcVvp: false,
    VVLLimit: 0,
    VVPMonthlyLimit: 0,
    VVPQuaterlyLimit: 0,
    VVPSemiAnnualLimit: 0,
    VVPAnnualLimit: 0,
    HasContribution: true,
    AvailableContributionAmount: 4400.0,
    SuggestedContributionPaymentAmount: 1000.0,
    IsVVContributionTargetingOK: true
  },
  colors: ['#F3B351'],
  planFamily: PlanFamillyList.ES,
  label: "​​Plan d'Epargne Retraite COllectif​",
  shortLabel: 'PERECO',
  horizon: 'Retraite'
};

export const mockOnGoingVVP: OnGoingVVP = {
  id: '1',
  planId: 1,
  planFamily: 'ES',
  planName: 'PEE',
  compartmentCode: '1001',
  compartmentLabel: 'Compartment',
  createdDate: '',
  nextPaymentDate: '',
  periodicity: ApiPaymentPeriodicityEnum['1M'],
  bankAccountDetail: null,
  amount: 100,
  managements: [],
  taxIn: false,
  taxOut: false
};

export const mockInstallmentEstmatedContribution: InstallmentCalculatedContributionState = {
  companyId: '4',
  planId: '3004',
  contributionTotalAmount: 150,
  distributions: [
    {
      managementId: '000022312',
      contributionAmount: 0,
      contributionAmountPaid: 0,
      supportTotalContributionAmount: 150,
      supports: []
    }
  ]
};

export const mockInstallmentBankDataCheck: OperationDecisionState = {
  elligibleDispositifs: [],
  dispositifs: [
    {
      id: '1501',
      name: 'PERO',
      encours: 4360.27,
      filterProperties: {
        VVL: true,
        VVP: true,
        TaxOut: true,
        TaxIn: true,
        RetirementOnly: true,
        Abondement: false,
        Invested: true,
        ThresholdTrigger: false,
        StockReallocation: true,
        PartialReallocation: false,
        FlowReallocation: true,
        OadReallocation: false,
        ImmediateAsset: false,
        ImmediatePension: false,
        Contribution: false
      },
      hasOnGoingRcVvp: false,
      planFamily: 'ES',
      advancedProperties: {},
      blocagesCodes: []
    },
    {
      id: '1501',
      name: 'PERO',
      encours: 4360.27,
      filterProperties: {
        VVL: true,
        VVP: true,
        TaxOut: true,
        TaxIn: true,
        RetirementOnly: true,
        Abondement: false,
        Invested: true,
        ThresholdTrigger: false,
        StockReallocation: true,
        PartialReallocation: false,
        FlowReallocation: true,
        OadReallocation: false,
        ImmediateAsset: false,
        ImmediatePension: false,
        Contribution: false
      },
      hasOnGoingRcVvp: false,
      planFamily: 'ES',
      advancedProperties: {},
      blocagesCodes: []
    },
    {
      id: '1501',
      name: 'PERO',
      encours: 4360.27,
      filterProperties: {
        VVL: true,
        VVP: true,
        TaxOut: true,
        TaxIn: true,
        RetirementOnly: true,
        Abondement: false,
        Invested: true,
        ThresholdTrigger: false,
        StockReallocation: true,
        PartialReallocation: false,
        FlowReallocation: true,
        OadReallocation: false,
        ImmediateAsset: false,
        ImmediatePension: false,
        Contribution: false
      },
      hasOnGoingRcVvp: false,
      planFamily: 'ES',
      advancedProperties: {},
      blocagesCodes: []
    }
  ],
  questions: [
    {
      key: 'VV_needs_VersementType',
      title: 'Je définis mon versement',
      text: 'VV_needs_VersementType',
      tooltip: 'Tooltip FR',
      respondedTitle: '',
      color: '#FF0000',
      showEligibility: false,
      responses: [
        {
          title: 'Ponctuel',
          text: "<p>J​'effectue un versement aujourd'hui.​<br></p>",
          propertyFilter: {
            name: 'VVL',
            value: true
          },
          options: [],
          type: ''
        },
        {
          title: 'Programmé',
          text: '<p>​Je programme un versement régulier&#160;&#58;<br><br><br><b>Attention &#58;</b>​ en l\'absence de coordonnées bancaires valides vous ne pouvez pas effectuer de versement programmé.​​&#160;​<a href="/coordonnees-bancaires/accueil">Je vérifie mes coordonnées bancaires</a><br>Votre opération sera prise en compte conformément au calendrier prévu par votre entreprise.</p>',
          propertyFilter: {
            name: 'VVP',
            value: true
          },
          options: [
            {
              name: 'VVP',
              value: '1M',
              labelCode: 'VV_option_1M'
            },
            {
              name: 'VVP',
              value: '3M',
              labelCode: 'VV_option_3M'
            },
            {
              name: 'VVP',
              value: '6M',
              labelCode: 'VV_option_6M'
            }
          ],
          type: ''
        }
      ],
      isRequired: true,
      saverDetails: {
        fullAddress: {
          additionalAddress_1: '',
          additionalAddress_2: '121   RUE LECOURBE',
          additionalAddress_3: null,
          street: null,
          country: 'FRANCE',
          zipcode: '75015',
          city: 'PARIS',
          recipient: 'M TEDGUY JERSZ'
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: 'FR760123012301230123040',
          bic: 'FRBNPP011',
          type: 'VIREMENT'
        },
        creditCardPaymentReferenceDetail: null,
        type: null
      }
    },
    {
      key: 'VV_needs_OptimisationAbondement',
      respondedTitle: '',
      title: "Optimiser mon abondement c'est",
      text: 'VV_needs_OptimisationAbondement',
      tooltip: 'VV_needs_OptimisationAbondement',
      color: '#00FF00',
      showEligibility: true,
      responses: [
        {
          title: 'Essentiel',
          text: 'Je veux investir uniquement sur les supports de placements abondés<a href="https&#58;//admin-authoring-epargne-retraite-entreprises-int.am.staging.echonet/mon-abondement"><strong>Tout savoir sur l\'abondement</strong><br><div><br><br></div></a>​',
          propertyFilter: {
            name: 'Abondement',
            value: true
          },
          options: [],
          type: ''
        },
        {
          title: 'Non essentiel',
          text: "Je veux être libre d'investir sur des supports de placement qu'ils soient abondés ou non",

          options: [],
          type: ''
        }
      ],
      isRequired: false,
      saverDetails: {
        fullAddress: {
          additionalAddress_1: '',
          additionalAddress_2: '121   RUE LECOURBE',
          additionalAddress_3: null,
          street: null,
          country: 'FRANCE',
          zipcode: '75015',
          city: 'PARIS',
          recipient: 'M TEDGUY JERSZ'
        },
        bankAccountDetail: null,
        creditCardPaymentReferenceDetail: null,
        type: null
      }
    },
    {
      key: 'VV_needs_JeChoisisMonBesoin',
      title: 'Je choisis mon besoin',
      respondedTitle: '',
      text: 'VV_needs_JeChoisisMonBesoin',
      tooltip: 'VV_needs_JeChoisisMonBesoin',
      color: '#00FF00',
      showEligibility: true,
      responses: [
        {
          title: "Je n'ai pas de besoin particulier",
          text: 'Je répartis mon versement sur des dispositifs à moyen ou long terme',

          options: [],
          type: ''
        },
        {
          title: 'Epargner pour un projet',
          text: 'Mon épargne sera disponible dans 5 ans (sauf en cas de déblocage anticipé)',
          propertyFilter: {
            name: 'RetirementOnly',
            value: false
          },
          options: [],
          type: ''
        },
        {
          title: 'Epargner pour ma retraite',
          text: 'Mon épargne sera disponible lors de mon départ à la retraite (sauf en cas de déblocage anticipé)',
          propertyFilter: {
            name: 'RetirementOnly',
            value: true
          },
          options: [],
          type: ''
        }
      ],
      isRequired: false,
      saverDetails: {
        fullAddress: {
          additionalAddress_1: '',
          additionalAddress_2: '121   RUE LECOURBE',
          additionalAddress_3: null,
          street: null,
          country: 'FRANCE',
          zipcode: '75015',
          city: 'PARIS',
          recipient: 'M TEDGUY JERSZ'
        },
        bankAccountDetail: null,
        creditCardPaymentReferenceDetail: null,
        type: null
      }
    },
    {
      key: 'VV_needs_JeChoisisCommentInvestir',
      title: 'Je choisis comment investir',
      respondedTitle: '',
      text: 'VV_needs_JeChoisisCommentInvestir',
      tooltip: 'VV_needs_JeChoisisCommentInvestir',
      color: '#00FF00',
      showEligibility: false,
      responses: [
        {
          title: 'Investir comme je veux',
          text: "Je veux investir sur tous les supports de placement qui m'intéressent",
          options: [],
          type: ''
        },
        {
          title: "Investir comme d'habitude",
          text: "Je veux investir uniquement sur les supports de placements sur lesquels j'ai déjà investi",
          propertyFilter: {
            name: 'Invested',
            value: true
          },
          options: [
            {
              name: 'SupportFilter',
              value: 'INVESTED',
              labelCode: 'INVESTED'
            }
          ],
          type: ''
        }
      ],
      isRequired: false,
      saverDetails: {
        fullAddress: {
          additionalAddress_1: '',
          additionalAddress_2: '121   RUE LECOURBE',
          additionalAddress_3: null,
          street: null,
          country: 'FRANCE',
          zipcode: '75015',
          city: 'PARIS',
          recipient: 'M TEDGUY JERSZ'
        },
        bankAccountDetail: null,
        creditCardPaymentReferenceDetail: null,
        type: null
      }
    }
  ]
};
