import { all, call, delay, fork, put, select } from '@redux-saga/core/effects';
import { Platform } from 'react-native';
import { takeLatest } from 'redux-saga/effects';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import {
  InstallmentPaymentFrequencyEnum,
  InstallmentPaymentStatusList,
  PlanFamillyList,
  InstallmentOperationName
} from '@constants/index';
import { InstallmentDecisionPropertiesEnum } from '@constants/installment';
import { RouteNames } from '@constants/navigation';
import { WebViewSourceTypeEnum } from '@ere-uilib/atoms';
import {
  NotificationSystemNameEnum,
  NotificationType
} from '@ere-uilib/types/notificationSystemTypes';
import { catchApiExceptions } from '@modules/apiAuthorization';
import { fetchEstimateCalculContributionByPlanData } from '@modules/common/services/contribution';
import { FilterPropertiesType } from '@modules/common/types';
import { getUsedCompanyId } from '@modules/dashboard/selectors';
import {
  getInstallmentDecisionAllResponses,
  getInstallmentEditedVVPDtata,
  getInstallmentPaymentMode,
  getInstallmentRepartitionData as getInstallmentRepartitionDataFromState,
  getInstallmentSelectedDispositif
} from '@modules/installment/selectors';
import {
  fetchCheckInstallmentPaymentStatusForId,
  fetchDeleteVVPById,
  fetchInstallmentPaymentModeAndBankAccountDetailData,
  fetchInstallmentRepartitionData,
  fetchInstallmentsDecision,
  fetchSendInstallmentPayment
} from '@modules/installment/services';
import {
  InstallmentCalculatedContributionState,
  InstallmentPaymentEndpointResponseType,
  InstallmentPaymentEndpointSendDataType,
  InstallmentRepartitionCompartmentManagementState,
  InstallmentRepartitionCompartmentManagementSupportState,
  InstallmentRepartitionState,
  PaymentModeState,
  PaymentResponseDataState
} from '@modules/installment/types';
import {
  buildCalculatedContributionDistributionsParameter,
  convertAPIPaymentPeriodicityToInstallmentDecisionVVPFrequency,
  convertInstallmentDecisionVVPFrequencyToAPIPaymentPeriodicity
} from '@modules/installment/utils';
import * as NotificationActions from '@modules/notification-system/actions/notificationSystemActions';
import { installmentHasMinSupportWithAmountGreaterThanZero } from '@modules/profit-sharing-incentive/utils/buildContributionUtil';
import * as savingActions from '@modules/savings/actions/savingsActions';
import { getOnGoingVVPHistory } from '@modules/savings/selectors';
import { OnGoingVVP } from '@modules/savings/types';
import { RootNavigation } from '@navigation/RootNavigation';

import { runManager } from '../moduleManager';
import * as InstallmentActions from './actions/installmentActions';
import {
  CheckInstallmentPaymentStatusForIdRequestAction,
  GetCalculatedContributionDataRequestAction,
  GetInstallmentPaymentModeAndBankAccountDetailRequestAction,
  GetInstallmentRepartitionDataRequestAction,
  InstallmentActionsType,
  OnVVPDeleteRequestAction,
  OnVVPEditRequestAction,
  SetInstallmentRepartitionAmountRequestAction
} from './actions/installmentActionsTypes';

export function* getInstallmentDecision(): any {
  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchInstallmentsDecision, companyId);
  // yield delay(1500);
  // const response = {
  //   data: mockInstallmentBankDataCheck
  // }
  yield put(InstallmentActions.getInstallmentDecisionSuccess(response?.data));
}

export function* getInstallmentRepartitionData(
  action: GetInstallmentRepartitionDataRequestAction
): any {
  const companyId = yield select(getUsedCompanyId);
  // get installments decision selectedPlan
  const decisionPlans = yield select(getInstallmentSelectedDispositif);
  const decisionPlanId = decisionPlans && decisionPlans?.length && decisionPlans[0]?.id;
  // get route plan id
  const routePlanId = action.routePlanId;
  // use routePlanId or decisionPlanId
  const selectedPlanId = routePlanId || decisionPlanId;

  const decisionFormResponses: FilterPropertiesType = yield select(
    getInstallmentDecisionAllResponses
  );

  // get installments decision responses
  const response = yield call(
    fetchInstallmentRepartitionData,
    companyId,
    selectedPlanId,
    decisionFormResponses
  );

  // yield delay(1500);
  // const response = {
  //   data: mockRepartitionTargetedData
  // }

  yield put(InstallmentActions.getInstallmentRepartitionDataSuccess(response?.data));
}
export function* setInstallmentRepartitionAmount(
  action: SetInstallmentRepartitionAmountRequestAction
): any {
  const installmentRepartitionData: InstallmentRepartitionState = yield select(
    getInstallmentRepartitionDataFromState
  );

  let returnedData: InstallmentCalculatedContributionState | undefined;
  let shouldResetEstimatedData = false;
  const targetedManagement = installmentRepartitionData?.compartments?.[0]?.managements?.find(
    management => {
      return management.id === action.params.managementId;
    }
  );
  const targetedSupport = targetedManagement?.supports?.find(support => {
    return support.supportIsin === action.params.fundId;
  });
  const isES = installmentRepartitionData.planFamily === PlanFamillyList.ES;
  const isRC = installmentRepartitionData.planFamily === PlanFamillyList.RC;
  const isPlanHasContribution = !!installmentRepartitionData?.advancedProperties?.HasContribution;
  const isPlanHasSimulation = !!installmentRepartitionData?.advancedProperties?.HasSimulation;
  const isManagementHasContribution = !!targetedManagement?.advancedProperties?.HasContribution;
  const isSupportHasContribution = targetedSupport?.hasContribution || targetedSupport?.isMaster;
  const shouldCalculContributionES =
    isES &&
    isPlanHasSimulation &&
    isPlanHasContribution &&
    isManagementHasContribution &&
    isSupportHasContribution;
  const shouldCalculContributionRC = isRC && isPlanHasContribution;

  if (shouldCalculContributionES || shouldCalculContributionRC) {
    // get Contribution from already calculated or ask for calcul
    const isContributionCalculated = action.params.contribution;

    if (isContributionCalculated) {
      // if no delay loading state is not listened as expected
      yield delay(100);
      returnedData = action.params.contribution;
    } else {
      const companyId = yield select(getUsedCompanyId);
      const planId = installmentRepartitionData.planId;
      // build distributions parameter
      const distributions = buildCalculatedContributionDistributionsParameter({
        installmentRepartitionData: installmentRepartitionData,
        managementId: action.params.managementId,
        fundId: action.params.fundId,
        amount: action.params.amount
      });

      const hasMinOneSupportToEstimate =
        installmentHasMinSupportWithAmountGreaterThanZero(distributions);
      try {
        if (hasMinOneSupportToEstimate) {
          const response = yield call(fetchEstimateCalculContributionByPlanData, {
            companyId,
            payload: [
              {
                planId,
                distributions
              }
            ],
            operationType: InstallmentOperationName
          });
          returnedData = response?.data?.[0] || null;
        } else {
          shouldResetEstimatedData = true;
          returnedData = null;
        }
      } catch (error: any) {
        yield put(
          NotificationActions.addNotification({
            uid: uuidv4(),
            type: NotificationType.WARNING,
            name: NotificationSystemNameEnum.CONTRIBUTION_ERROR
          })
        );
        yield catchApiExceptions(
          InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_FAILURE,
          error,
          action
        );
      }
    }
  }

  yield put(
    InstallmentActions.setInstallmentRepartitionAmountSuccess({
      calculatedContribution: returnedData,
      installmentAmountValidationParams: action.params,
      shouldResetEstimationData: shouldResetEstimatedData
    })
  );
}

function* getCalculatedContributionData(action: GetCalculatedContributionDataRequestAction): any {
  const companyId = yield select(getUsedCompanyId);
  const installmentRepartitionData: InstallmentRepartitionState = yield select(
    getInstallmentRepartitionDataFromState
  );
  const planId = installmentRepartitionData.planId;

  // build distributions parameter
  const distributions = buildCalculatedContributionDistributionsParameter({
    installmentRepartitionData: installmentRepartitionData,
    managementId: action.params.managementId,
    fundId: action.params.fundId,
    amount: action.params.amount
  });

  const hasMinOneSupportToEstimate =
    installmentHasMinSupportWithAmountGreaterThanZero(distributions);

  if (hasMinOneSupportToEstimate) {
    const response = yield call(fetchEstimateCalculContributionByPlanData, {
      companyId,
      payload: [
        {
          planId,
          distributions
        }
      ],
      operationType: InstallmentOperationName
    });
    yield put(InstallmentActions.getCalculatedContributionDataSuccess(response?.data[0] || null));
  } else {
    yield put(InstallmentActions.getCalculatedContributionDataSuccess(null));
  }
}

function* getInstallmentPaymentModeAndBankAccountDetailData(
  action: GetInstallmentPaymentModeAndBankAccountDetailRequestAction
): any {
  const companyId = yield select(getUsedCompanyId);
  // get installments decision selectedPlan
  const decisionPlans = yield select(getInstallmentSelectedDispositif);
  const decisionPlanId = decisionPlans && decisionPlans?.length && decisionPlans[0]?.id;
  // get route plan id
  const routePlanId = action.routePlanId;
  // use routePlanId or decisionPlanId
  const selectedPlanId = routePlanId || decisionPlanId;

  const decisionFormResponses: FilterPropertiesType = yield select(
    getInstallmentDecisionAllResponses
  );
  const paymentFrequency: InstallmentPaymentFrequencyEnum = decisionFormResponses?.VVL
    ? InstallmentPaymentFrequencyEnum.VVL
    : InstallmentPaymentFrequencyEnum.VVP;
  const response = yield call(
    fetchInstallmentPaymentModeAndBankAccountDetailData,
    companyId,
    selectedPlanId,
    paymentFrequency
  );
  if (response?.data?.length === 1 && response.data[0].paymentMethod) {
    yield put(
      InstallmentActions.setInstallmentPaymentModeChoice(
        response.data[0].paymentMethod as PaymentModeState
      )
    );
  }

  yield put(
    InstallmentActions.getInstallmentPaymentModeAndBankAccountDetailSuccess(response?.data)
  );
}

function* sendInstallmentPaymentData(): any {
  yield put(InstallmentActions.installmentInitError());
  const companyId: string = yield select(getUsedCompanyId);
  const installmentRepartitionData: InstallmentRepartitionState = yield select(
    getInstallmentRepartitionDataFromState
  );
  const decisionFormResponses: FilterPropertiesType = yield select(
    getInstallmentDecisionAllResponses
  );
  const installmentPaymentMode = yield select(getInstallmentPaymentMode);
  const installmentEditedVVPDtata: OnGoingVVP | undefined = yield select(
    getInstallmentEditedVVPDtata
  );
  const isEditMode = !!installmentEditedVVPDtata;

  // define payment infos keys
  const paymentType: InstallmentPaymentEndpointSendDataType['paymentType'] =
    decisionFormResponses.VVL
      ? InstallmentPaymentFrequencyEnum.VVL
      : InstallmentPaymentFrequencyEnum.VVP;
  let paymentPeriodicity: InstallmentPaymentEndpointSendDataType['paymentPeriodicity'] | undefined;
  if (paymentType === InstallmentPaymentFrequencyEnum.VVP && decisionFormResponses.VVP) {
    paymentPeriodicity = convertInstallmentDecisionVVPFrequencyToAPIPaymentPeriodicity(
      decisionFormResponses.VVP
    );
  }

  // construct plan repartition infos

  const filteredManagements = installmentRepartitionData?.compartments?.[0]?.managements?.filter(
    (item: InstallmentRepartitionCompartmentManagementState) =>
      typeof item?.formValues?.totalAmount === 'number' && item.formValues.totalAmount > 0
  );

  const paymentEndpointplanManagements = filteredManagements?.map(management => {
    const filteredFunds = management.supports.filter(
      (item: InstallmentRepartitionCompartmentManagementSupportState) =>
        typeof item?.formValues?.amount === 'number' && item.formValues.amount > 0
    );
    return {
      id: management.id,
      amount: management.formValues?.totalAmount || 0,
      funds: filteredFunds.map(fund => ({
        supportId: fund.supportIsin,
        amount: fund.formValues?.amount || 0
      }))
    };
  });

  // construct endpoint data to send
  const installmentPaymentEndpointData: InstallmentPaymentEndpointSendDataType = {
    paymentMethod: installmentPaymentMode,
    paymentType,
    ...(paymentPeriodicity && { paymentPeriodicity }),
    planFamily: installmentRepartitionData?.planFamily,
    totalAmount: installmentRepartitionData?.formValues?.totalAmount || 0,
    planManagements: paymentEndpointplanManagements,
    ...(isEditMode &&
      installmentEditedVVPDtata?.id && {
        idVersementProgramme: installmentEditedVVPDtata.id
      })
  };
  let paymentResponseData: PaymentResponseDataState = {};
  const response = yield call(fetchSendInstallmentPayment, {
    installmentPaymentEndpointData,
    companyId,
    planId: installmentRepartitionData?.planId
  });
  const responseData: InstallmentPaymentEndpointResponseType | undefined = response?.data;
  paymentResponseData = {
    ...paymentResponseData,
    payementHtmlPageCode: responseData?.redirectionPage || ''
  };
  yield put(InstallmentActions.SendInstallmentPaymentDataSuccess(paymentResponseData));
  // check response to redirect
  if (responseData?.transactionStatus === 'OK') {
    // we have the positive status of operation
    if (responseData?.redirectionPage) {
      // we need to manage CB payment
      // ES and RC have two different way to go to Paiement
      // in RC redirectionPage is html code of the page to display to make it redirect.
      if (installmentRepartitionData?.planFamily === PlanFamillyList.RC) {
        // we set paymentResponseData with html page code to let InstallmentPaymentCBHtml page
        // get it from redux state
        // Navigate to InstallmentPaymentCBHtml to display recieved html code (markanet service)
        yield RootNavigation.navigate(RouteNames.InstallmentStack, {
          screen: RouteNames.InstallmentPaymentCBHtml,
          params: {
            source: WebViewSourceTypeEnum.HTML
          }
        });
      }
      // in ES redirectionPage is an url to paiement page.
      if (installmentRepartitionData?.planFamily === PlanFamillyList.ES) {
        // we open the url on same tab in web
        if (Platform.OS === 'web') {
          window.open(responseData.redirectionPage, '_self');
        } else {
          // Navigate to InstallmentPaymentCBHtml to display recieved uri
          yield RootNavigation.navigate(RouteNames.InstallmentStack, {
            screen: RouteNames.InstallmentPaymentCBHtml,
            params: {
              source: WebViewSourceTypeEnum.URI
            }
          });
        }
      }
    } else {
      yield RootNavigation.navigate(RouteNames.InstallmentStack, {
        screen: RouteNames.InstallmentSuccess
      });
    }
  } else {
    // we have not the positive status of operation
    yield RootNavigation.navigate(RouteNames.InstallmentStack, {
      screen: RouteNames.InstallmentFailed
    });
  }
}

function* checkInstallmentPaymentStatusForId(
  action: CheckInstallmentPaymentStatusForIdRequestAction
): any {
  yield put(InstallmentActions.installmentInitError());
  const response = yield call(fetchCheckInstallmentPaymentStatusForId, action.id);

  switch (response?.data?.status) {
    case InstallmentPaymentStatusList.OK:
      yield RootNavigation.replace(RouteNames.InstallmentStack, {
        screen: RouteNames.InstallmentSuccess
      });
      yield put(InstallmentActions.CheckInstallmentPaymentStatusForIdSuccess());
      break;
    case InstallmentPaymentStatusList.KO:
      yield RootNavigation.replace(RouteNames.InstallmentStack, {
        screen: RouteNames.InstallmentFailed
      });
      yield put(InstallmentActions.CheckInstallmentPaymentStatusForIdSuccess());
      break;
    case InstallmentPaymentStatusList.IN_PROGRESS:
      yield delay(5000);
      yield put(InstallmentActions.CheckInstallmentPaymentStatusForIdRequest(action.id));
      break;
    default:
      const error = {
        code: '',
        correlationId: '',
        message: 'Error',
        innerMessage: 'Error'
      };
      yield catchApiExceptions(
        InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_FAILURE,
        error
      );
      break;
  }
}

function* onVVPEdit(action: OnVVPEditRequestAction): any {
  yield put(InstallmentActions.installmentInitError());
  const companyId: string = yield select(getUsedCompanyId);
  const vvpHistory: OnGoingVVP[] = yield select(getOnGoingVVPHistory);
  const targetedVVP = vvpHistory.find(item => item.id === action.operationId);

  if (!targetedVVP) {
    yield catchApiExceptions(InstallmentActionsType.ON_VVP_EDIT_FAILURE, {});
    return;
  }

  const planId = `${targetedVVP.planId}`;

  const decisionFormResponses = {
    VVP: convertAPIPaymentPeriodicityToInstallmentDecisionVVPFrequency(targetedVVP.periodicity),
    ...(targetedVVP.taxIn && { [InstallmentDecisionPropertiesEnum.TAX_IN]: true }),
    ...(targetedVVP.taxOut && { [InstallmentDecisionPropertiesEnum.TAX_OUT]: true })
  };

  // get installments decision responses
  const repartition: {
    data: InstallmentRepartitionState;
  } = yield call(fetchInstallmentRepartitionData, companyId, planId, decisionFormResponses);
  yield put(savingActions.setIsDetailsVvpPopin(false));
  yield put(
    InstallmentActions.onEditVVPSuccess({
      editedVVPData: targetedVVP,
      revievedRepartition: repartition.data
    })
  );

  RootNavigation.navigate(RouteNames.InstallmentStack, {
    screen: RouteNames.InstallmentsRepartition
  });
}

function* onVVPDelete(action: OnVVPDeleteRequestAction): any {
  yield put(InstallmentActions.installmentInitError());
  const companyId: string = yield select(getUsedCompanyId);

  yield call(fetchDeleteVVPById, {
    companyId: companyId,
    planId: action.planId,
    operationId: action.operationId
  });

  yield put(savingActions.setOnGoingVVPDeleteModalVisibleState(false));

  yield put(InstallmentActions.onDeleteVVPSuccess());

  RootNavigation.navigate(RouteNames.BottomTabNavigator, {
    screen: RouteNames.SavingsStack,
    params: {
      screen: RouteNames.Savings
    }
  });
}

export function* getInstallmentDecisionSagas(): any {
  yield takeLatest(
    InstallmentActionsType.GET_INSTALLMENT_DECISION_REQUEST,
    runManager(getInstallmentDecision, InstallmentActionsType.GET_INSTALLMENT_DECISION_FAILURE)
  );
}

export function* getInstallmentRepartitionDataSagas(): any {
  yield takeLatest(
    InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_REQUEST,
    runManager(
      getInstallmentRepartitionData,
      InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_FAILURE
    )
  );
}

export function* setInstallmentRepartitionAmountSagas(): any {
  yield takeLatest(
    InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_REQUEST,
    runManager(
      setInstallmentRepartitionAmount,
      InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_FAILURE
    )
  );
}

export function* getInstallmentPaymentModeAndBankAccountDetailDataSagas(): any {
  yield takeLatest(
    InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_REQUEST,
    runManager(
      getInstallmentPaymentModeAndBankAccountDetailData,
      InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_FAILURE
    )
  );
}

export function* getCalculatedContributionDataSagas(): any {
  yield takeLatest(
    InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_REQUEST,
    runManager(
      getCalculatedContributionData,
      InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_FAILURE
    )
  );
}

export function* sendInstallmentPaymentDataSagas(): any {
  yield takeLatest(
    InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_REQUEST,
    runManager(
      sendInstallmentPaymentData,
      InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_FAILURE
    )
  );
}
export function* checkInstallmentPaymentStatusForIdSagas(): any {
  yield takeLatest(
    InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_REQUEST,
    runManager(
      checkInstallmentPaymentStatusForId,
      InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_FAILURE
    )
  );
}

export function* onVVPEditSagas(): any {
  yield takeLatest(
    InstallmentActionsType.ON_VVP_EDIT_REQUEST,
    runManager(onVVPEdit, InstallmentActionsType.ON_VVP_EDIT_FAILURE)
  );
}

export function* onVVPDeleteSagas(): any {
  yield takeLatest(
    InstallmentActionsType.ON_VVP_DELETE_REQUEST,
    runManager(onVVPDelete, InstallmentActionsType.ON_VVP_DELETE_FAILURE)
  );
}

export function* InstallmentSagas() {
  yield all([
    fork(getInstallmentRepartitionDataSagas),
    fork(getInstallmentDecisionSagas),
    fork(setInstallmentRepartitionAmountSagas),
    fork(getInstallmentPaymentModeAndBankAccountDetailDataSagas),
    fork(getCalculatedContributionDataSagas),
    fork(sendInstallmentPaymentDataSagas),
    fork(checkInstallmentPaymentStatusForIdSagas),
    fork(onVVPEditSagas),
    fork(onVVPDeleteSagas)
  ]);
}
