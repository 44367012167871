import axios from "axios";

import { API_URL } from "@config/index";
import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from "@constants/index"
import {
  ProfitSharingIncentiveSubmitAllocationParamsType,
  ProfitSharingIncentiveSubmitAllocationResponseType
} from "@modules/profit-sharing-incentive/types"

export async function fetchProfitSharingIncentiveInitData({
  companyId,
  type,
  legalFramework,
  transactionId
}:{
  companyId: string,
  type: ProfitSharingIncentiveTypeEnum,
  legalFramework: ProfitSharingIncentiveLegalFrameworkEnum,
  transactionId?: string
}): Promise<{}> {
  const transactionIdPath = !!transactionId ? `&transactionId=${transactionId}` : "";
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/bulletin-options?type=${type}&legalFramework=${legalFramework}${transactionIdPath}`);
}

export async function fetchProfitSharingIncentiveRepartition({
  companyId,
  type,
  transactionId
}:{
  companyId: string,
  type: ProfitSharingIncentiveTypeEnum;
  transactionId?: string
}): Promise<{}> {
  const transactionIdPath = !!transactionId ? `?transactionId=${transactionId}` : '';
  return axios.post(`${API_URL}/api/v1/companies/${companyId}/bulletin-options/${type}/plans${transactionIdPath}`);
}

export async function fetchProfitSharingIncentiveSubmitAllocation({
  companyId,
  params
}:ProfitSharingIncentiveSubmitAllocationParamsType
): Promise<ProfitSharingIncentiveSubmitAllocationResponseType> {
  return axios.post(
    `${API_URL}/api/v1/demands/companies/${companyId}/bulletinallocation`,
    params
  );
}
