import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = (addedValue?: number) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          minHeight: isDesktop ? theme.metrics.spacing.xxbig : theme.metrics.spacing.huge,
          paddingHorizontal: theme.metrics.spacing.s,
          flex: 1,
          flexDirection: 'row',
          justifyContent: isDesktop ? 'center' : 'space-between',
          alignItems: 'center',
          paddingVertical: theme.metrics.spacing.xm
        },
        buttonView: {
          width: theme.metrics.iconSizes.xl,
          height: theme.metrics.iconSizes.xl
        },
        amountContainer: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 497
        },
        titleContainer: {
          flexDirection: 'row',
          justifyContent: 'center'
        },
        amount: {
          textAlign: 'center'
        },
        helpPointStyle: {
          marginRight: theme.metrics.spacing.s
        },
        estimatedNetAmount: {
          textAlign: 'center',
          marginTop: theme.metrics.spacing.xxs
        }
      }),
    [addedValue, isDesktop, theme]
  );

  return styles;
};
