import React from 'react';
import { View } from 'react-native';

import { SavingsHeaderBand, LinkBar } from '@ere-uilib/molecules';
import { HelpPointType } from '@ere-uilib/organisms/HelpPoint/interfaces';

import { useStyles } from './styles';

interface SavingsHeaderProps {
  innerNavigations: string[];
  selectedTab: string;
  onTabChange: (tabId: string) => void;
  addedValue?: number;
  titleKey: string;
  amount?: number;
  swipeLeft: (nav: string) => void;
  swipeRight: (nav: string) => void;
  savingPlansLength: number;
  selectedPlan: number;
  helpPoint?: HelpPointType;
  estimatedNetAmount?: number;
  isEstimatedBeforeTax?: boolean;
  dateAddedValue?: string;
  isNotElligbleRcType: boolean;
}

export const SavingsHeader = ({
  innerNavigations,
  selectedTab,
  onTabChange,
  addedValue,
  titleKey,
  swipeRight,
  swipeLeft,
  amount,
  savingPlansLength,
  selectedPlan,
  helpPoint,
  estimatedNetAmount,
  isEstimatedBeforeTax,
  dateAddedValue,
  isNotElligbleRcType
}: SavingsHeaderProps) => {
  const styles = useStyles((!!addedValue || !!estimatedNetAmount) && !!helpPoint, {});
  return (
    <View style={styles.container}>
      <SavingsHeaderBand
        addedValue={addedValue}
        amount={amount}
        dateAddedValue={dateAddedValue}
        estimatedNetAmount={estimatedNetAmount}
        helpPoint={helpPoint}
        isEstimatedBeforeTax={isEstimatedBeforeTax}
        isNotElligbleRcType={isNotElligbleRcType}
        savingPlansLength={savingPlansLength}
        selectedPlan={selectedPlan}
        swipeLeft={() => swipeLeft(selectedTab)}
        swipeRight={() => swipeRight(selectedTab)}
        titleKey={titleKey}
      />
      <View>
        <LinkBar
          items={innerNavigations}
          onSelect={onTabChange}
          selectedItem={selectedTab}
          testId="savings"
        />
      </View>
    </View>
  );
};
